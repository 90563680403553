import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: any) => {
  return httpRequest.post("/erp/back_office/item_reference/section", data);
};

export const select = async () => {
  return httpRequest.get("/erp/back_office/item_reference/section/select");
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/back_office/item_reference/section", { offset, filter });
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/back_office/item_reference/section/${id}`);
};

export const update = async (id: string, data: { name: string; isPublic: boolean }) => {
  return httpRequest.put(`/erp/back_office/item_reference/section/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/back_office/item_reference/section/${id}`);
};
