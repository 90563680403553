import { Badge, Button, Divider, Flex, Modal, Text, Tooltip } from "@mantine/core";
import { IconInfoSquareRounded } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { InvoiceApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { PaymentTermForm } from "../../../components/payment-term/form";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IPaymentTerm } from "../../../interfaces/IPaymentTerm";
import { IReference } from "../../../models/General";

export function ReferencePaymentTermList() {
  const params = useParams();
  const ref = useRef<ITableRef>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [filters] = React.useState({ condition: `${params?.type}`, configType: "", query: "", isActive: "", isOpen: "", isMain: "" });
  const { paymentTermConditions, paymentTermConfigTypes } = useSelector((state: { general: IGeneral }) => state.general);

  const title = (val: string) => {
    switch (val) {
      case "COD": {
        return "Бэлэн төлөх нөхцөл";
      }
      case "INV_CONFIG": {
        return "Нэхэмжлэхээр төлөх нөхцөл";
      }
      case "AR_NET30": {
        return "Авлага";
      }
    }
  };

  const subTitle = (val: string) => {
    switch (val) {
      case "COD": {
        return "Бэлэн төлөх нөхцөлийн жагсаалт";
      }
      case "INV_CONFIG": {
        return "Нэхэмжлэхээр төлөх нөхцөлийн жагсаалт";
      }
      case "AR_NET30": {
        return "Авлага жагсаалт";
      }
    }
  };

  const breadcrumbs = useBreadcrumb(title(params?.type as string));

  const columns = useHeader({
    paymentTermConfigTypes,
    paymentTermConditions,
    onClick: (key, record) => {
      switch (key) {
        case "detail": {
          setAction(["detail", record as any]);
          break;
        }
      }
    },
  });

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <>
      <PageLayout
        title={`${title(params?.type as string)}`}
        subTitle={`${subTitle(params?.type as string)}`}
        breadcrumb={breadcrumbs}
        extra={[
          <Button
            key={1}
            onClick={() => {
              setAction(["new"]);
            }}
            disabled={params.type === "AR_NET30"}>
            Нэмэх
          </Button>,
        ]}>
        <Divider color="#e5e5e5" my="md" />
        <Table ref={ref} name="partner-management-partner-admin's.list" columns={columns} filters={filters} loadData={(data) => InvoiceApi.list(data!)} />
      </PageLayout>
      <Modal opened={action[0] === "new" || action[0] === "detail"} onClose={() => setAction([])} size="60%" withCloseButton={false} centered>
        <PaymentTermForm action={action} setAction={setAction} reload={reload} />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IPaymentTerm) => void;
  paymentTermConfigTypes: IReference[];
  paymentTermConditions: IReference[];
};

const useHeader = ({ onClick, paymentTermConfigTypes, paymentTermConditions }: HeaderProps): ColumnType<IPaymentTerm>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Flex>
          <Tooltip label="Дэлгэрэнгүй" position="top">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquareRounded />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Badge variant="outline" size="md" radius="sm">
          {record?.refCode || "-"}
        </Badge>
      );
    },
  },
  {
    title: "Нөхцөл",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {paymentTermConditions?.find((c) => c?.code === record?.condition)?.name}
        </Text>
      );
    },
  },
  {
    title: "Захиалга баталгаажих",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {paymentTermConfigTypes?.find((c) => c?.code === record?.configType)?.text ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" lineClamp={2} style={{ whiteSpace: "nowrap" }} span>
          {record?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Expire day count",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.expireDayCount || "-"}
        </Text>
      );
    },
  },
  {
    title: "Confirmation day",
    sorter: true,
    dataIndex: "registerNo",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.confirmationDay || "-"}
        </Text>
      );
    },
  },
  {
    title: "Payment day",
    sorter: true,
    dataIndex: "departmentUnit",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.paymentDay || "-"}
        </Text>
      );
    },
  },
  {
    title: "DeHUB - Public",
    sorter: true,
    dataIndex: "isOpen",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isOpen ? "green" : "red"}>
          {record.isOpen ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үндсэн эсэх",
    sorter: true,
    dataIndex: "isMain",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isMain ? "green" : "red"}>
          {record.isMain ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Идэвхтэй эсэх",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isActive ? "green" : "red"}>
          {record.isActive ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
];

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/reference/b2b-list",
    label: "Төлбөрийн нөхцөл",
  },
  {
    label: type,
  },
];
