import { ActionIcon, Button, Divider, Flex, Grid, Group, Input, LoadingOverlay, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import React from "react";
import * as yup from "yup";
import { sectorApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  name: yup.string().max(100, "Хэр хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  parentId: string;
  bogdCode: string;
  golomtCode: string;
  name: string;
  isPublic: boolean;
};

export function BasicSectorForm({ reload, onCancel }: { reload: () => void; onCancel: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    parentId: "",
    bogdCode: "",
    golomtCode: "",
    name: "",
    isPublic: false,
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await sectorApi.create({
        name: value.name,
        isPublic: value.isPublic,
      });

      onCancel && onCancel();
      reload();
      Message.success("Амжилттай үндсэн ангилал нэмлээ!");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout
      my={0}
      title="Үндсэн ангилал нэмэх"
      subTitle="Dehub бизнес платформийн эдийн засгийн үндсэн ангиллын бүртгэл"
      extra={[
        <ActionIcon onClick={() => onCancel()} key={1}>
          <IconX />
        </ActionIcon>,
      ]}>
      <LoadingOverlay visible={loading} />
      <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
        {({ values }) => {
          return (
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <TextField name="name" placeholder="Нэр" label="Нэр" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Input.Wrapper label="Нээлттэй">
                  <Group>
                    <CheckboxField name="isPublic" label="" />
                    <Text size="sm" fw={500} c="#44566C" mt={11}>
                      {values.isPublic ? "Тийм" : "Үгүй"}
                    </Text>
                  </Group>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>

              <Grid.Col span={12}>
                <Flex justify="space-between">
                  <Button color="gray" variant="outline" onClick={() => onCancel && onCancel()}>
                    Буцах
                  </Button>
                  <Button loading={loading} type="submit">
                    Хадгалах
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>
    </FormLayout>
  );
}
