import { Badge, Input, Text, Tooltip } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useDebouncedState } from "@mantine/hooks";
import { IconSearch } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { ItemReferenceApi } from "../../../apis";
import { PageFilter, PageLayout } from "../../../components/layout";
import { ColumnType, Table } from "../../../components/table";
import { IReference } from "../../../interfaces/IReference";

export function ReferenceProductList() {
  const breadcrumbs = useBreadcrumb();
  const { classes } = useStyles();
  const [query, setQuery] = useDebouncedState("", 500);

  const columns = useHeader({
    classes,
  });

  return (
    <>
      <PageLayout title="Барааны лавлах мэдээлэл" subTitle="Барааны лавлах мэдээллийн жагсаалт" breadcrumb={breadcrumbs}>
        <PageFilter
          left={[]}
          right={[
            <Tooltip key={0} label="Лавлахын нэр, кодоор хайх" color="indigo" position="top-end" withArrow>
              <Input
                onChange={(e: any) => setQuery(e.currentTarget.value)}
                placeholder="Хайлт хийх"
                rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
              />
            </Tooltip>,
          ]}
        />

        <br />
        <Table name="reference.list" columns={columns} filters={{ query: query, type: "" }} loadData={(data) => ItemReferenceApi.list(data!)} />
      </PageLayout>
    </>
  );
}

const useHeader = ({ classes }: { classes: { [key: string]: string } }): ColumnType<IReference>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Лавлах код",
    sorter: true,
    dataIndex: "ref",
    width: 140,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Лавлах нэр",
    sorter: true,
    dataIndex: "type",
    width: 240,
    render: (record) => {
      return (
        <Link to={`/reference/product-list/${record.listType}`}>
          <Badge radius="sm" color="indigo.7" className={classes.reference} variant="outline">
            {record.name}
          </Badge>
        </Link>
      );
    },
  },
  {
    title: "Лавлах төрөл",
    sorter: true,
    dataIndex: "list",
    width: 240,
    render: (record) => {
      return (
        <Badge radius="sm" color="blue.5" variant="outline">
          {record.listType}
        </Badge>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.description || "-"}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Барааны лавлах мэдээлэл",
  },
];

const useStyles = createStyles((theme) => ({
  reference: {
    "&:hover": {
      color: theme.colors.dark[9],
    },
  },
}));
