import { Badge, Button, Divider, Group, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { categoryApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { SubCategoryFormEdit } from "./sub-categories-form";
import { SubCategoryForm } from "./sub-category-form";

export function SubCategoryList() {
  const navigate = useNavigate();
  const [action, setAction] = React.useState<string[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["update", item as any]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await categoryApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  Message.success("Амжилттай категорыг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  Message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setAction([]);
  };

  const reload = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Дэд категори"
        subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний дэд категорийн жагсаалт"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference/product-list")}>
            Буцах
          </Button>,
          <Button
            key={2}
            onClick={() => {
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my={10} />
        <Table
          ref={ref}
          name="sub-category.list"
          columns={columns}
          filters={{ query: "", type: "SUB_CATEGORY" }}
          loadData={(data) => categoryApi.list(data!)}
        />
      </PageLayout>
      <Modal opened={action[0] === "new"} onClose={() => setAction([])} withCloseButton={false} centered size="60%">
        <SubCategoryForm title="Дэд категорийн мэдээлэл" subTitle="Дэд категорийн нэр, тайлбар, талбаруудыг нэмэх" onCancel={onCancel} reload={reload} />
      </Modal>
      <Modal opened={action && action[0] === "update"} onClose={() => setAction([])} withCloseButton={false} centered size={"60%"}>
        <SubCategoryFormEdit
          title="Дэд категорийн мэдээлэл"
          subTitle="Дэд категорийн нэр, тайлбар, засах"
          onCancel={onCancel}
          action={action}
          reload={reload}
        />
      </Modal>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: ICategory) => void }): ColumnType<ICategory>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Код",
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" span>
          {record?.refCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр төрөл",
    dataIndex: "itemType",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" span>
          {record?.itemType?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Ангилал",
    dataIndex: "classification",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" span>
          {record?.classification?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Дэд ангилал",
    dataIndex: "subClassification",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.subClassification?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Категори",
    dataIndex: "category",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.category?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нээлттэй эсэх",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green" : "red"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Group>
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
            <IconTrash />
          </Button>
        </Group>
      );
    },
  },
];
