import { Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";

export const Dialog = {
  confirm: (text: string, callback: (key: "confirm" | "cancel") => void) =>
    openConfirmModal({
      title: "Баталгаажуулах",
      children: <Text size="sm">{text}</Text>,
      labels: {
        confirm: "Тийм",
        cancel: "Үгүй",
      },
      onCancel: () => callback("cancel"),
      onConfirm: () => callback("confirm"),
      centered: true,
    }),
};
