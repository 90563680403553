import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const me = async () => {
  const res = await httpRequest.get("/2fa/auth/me");
  return res;
};

export const checkPin = async (data: { pin: string }) => {
  return httpRequest.put("/2fa/auth/pin/check", data);
};

export const changePin = async (data: any) => {
  return httpRequest.put("/2fa/auth/pin", data);
};

export const login = async (data: any) => {
  const res = await httpRequest.post(`/2fa/auth/login`, data);
  return res;
};

export const logout = async () => {
  const res = await httpRequest.get("/2fa/auth/logout");
  return res;
};

export const forgot = async (data: any) => {
  const res = await httpRequest.put("/2fa/auth/forgot", data);
  return res;
};

export const verify = async (id: string) => {
  const res = await httpRequest.get(`/2fa/auth/${id}/verify`);
  return res;
};

export const createPin = async (data: any) => {
  const res = await httpRequest.post(`/2fa/auth/pin`, data);
  return res;
};

export const updatePin = async (data: any) => {
  const res = await httpRequest.put(`/2fa/auth/pin`, data);
  return res;
};
