import { Badge, Divider, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { ReferenceApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { ColumnType, Table } from "../../../components/table";
import { IReference } from "../../../interfaces/IReference";
import { dateTimeFormat } from "../../../utils/date";

export function ReferenceB2bList() {
  const breadcrumbs = useBreadcrumb();
  const columns = useHeader();

  return (
    <PageLayout title="Төлбөрийн нөхцөл" subTitle="Төлбөрийн нөхцөлийн мэдээллийн жагсаалт" breadcrumb={breadcrumbs}>
      <Divider color="#e5e5e5" my="md" />
      <Table name="reference-b2b.list" columns={columns} filters={{ query: "" }} loadData={(data) => ReferenceApi.list(data!)} />
    </PageLayout>
  );
}

const useHeader = (): ColumnType<IReference>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Badge radius="sm" variant="outline">
          {record?.refCode}
        </Badge>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "listType",
    render: (record) => {
      if (record?.listType === "REGION" || record?.listType === "DIRECTION") {
        return (
          <Badge radius="sm" variant="outline">
            {record?.name || "-"}
          </Badge>
        );
      } else {
        return (
          <Link to={`/reference/b2b-list/payment-term/${record?.listType}`}>
            <Badge radius="sm" variant="filled">
              {record?.name || "-"}
            </Badge>
          </Link>
        );
      }
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "description",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.description || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateTimeFormat(record?.createdAt)}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Лавлах мэдээлэл",
  },
];
