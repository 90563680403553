import { DatePickerInput } from "@mantine/dates";
import { useField } from ".";

interface IDatePickerField {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder: string;
  rightSection?: React.ReactNode;
  inputFormat?: string;
  required?: boolean;
}

export function DatePickerField({ name, label, disabled = false, rightSection, placeholder, inputFormat, required }: IDatePickerField) {
  const { value, onChange } = useField(name);

  return (
    <DatePickerInput
      label={label}
      disabled={disabled}
      rightSection={rightSection}
      value={value ? new Date(value) : null}
      onChange={(e: any) => onChange(new Date(e))}
      placeholder={placeholder}
      valueFormat={inputFormat}
      required={required}
    />
  );
}
