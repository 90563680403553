import { Anchor, Button, Divider, Flex, Grid, Input, Space, Tabs, Text } from "@mantine/core";
import { IconBrandFacebook, IconBrandInstagram, IconBrandTelegram, IconBrandTwitter, IconPhoneCall, IconSettingsAutomation } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { PartnerApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { registerNumber } from "../../utils";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { DatePickerField } from "../form/date-picker-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { IdentityCardUpload } from "../upload/identity-card-upload";

type IFormData = {
  partnerId: string | undefined;
  registerNo?: string | undefined;
  roleId?: string | undefined;
  familyName?: string | undefined;
  lastName?: string | undefined;
  firstName?: string | undefined;
  departmentUnitId?: string | undefined;
  employeeUnitId?: string | undefined;
  roleEnableDate?: string | undefined;
  phone?: string | undefined;
  phone2?: string | undefined;
  email?: string | undefined;
  email2?: string | undefined;
  socialFbLink?: string | undefined;
  departmentSubUnitId?: string | undefined;
  socialLinks: {
    type?: string | undefined;
    link?: string | undefined;
  }[];
  identityCardFront?: string | undefined;
  identityCardBack?: string | undefined;
  username?: string | undefined;
};

type Props = {
  action: any[];
  setAction: any;
  reload: () => void;
};

const schema = yup.object({
  registerNo: yup.string().matches(registerNumber, "Регистр дугаараа дахин шалгана уу!").required("Заавал бөглөнө!"),
  familyName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .trim()
    .required("Заавал бөглөнө!"),
  lastName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-_]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .trim()
    .required("Заавал бөглөнө!"),
  firstName: yup
    .string()
    .matches(/^[А-Яа-я|Үү|Өө|Ёё\s-_]{2,256}$/, "Зөвхөн кирил үсэг авна")
    .trim()
    .required("Заавал бөглөнө!"),
  departmentUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  departmentSubUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").optional(),
  employeeUnitId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  phone: yup
    .string()
    .required("Заавал бөглөнө!")
    .matches(/^[9|8|7|6]{1}[0-9]{7}$/, "Утасны дугаарын формат буруу байна!")
    .min(8, "Утасны дугаар 8 оронтой тоо байна!"),
  phone2: yup
    .string()
    .matches(/^[9|8|7|6]{1}[0-9]{7}$/, "Утасны дугаарын формат буруу байна!")
    .min(8, "Утасны дугаар 8 оронтой тоо байна!"),
  email: yup.string().email("Цахим шууданг дахин шалгана уу!").required("Заавал бөглөнө!").trim().nullable(),
  email2: yup.string().email("Цахим шууданг дахин шалгана уу!").trim().nullable(),
  socialFbLink: yup
    .string()
    .transform((value) => (value ? value : null))
    .matches(/^http(s*):\/\/(www.)*facebook\.com\/[a-zA-Z0-9.]+$/i, "Facebook url биш байна!")
    .nullable(),
  identityCardFront: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  identityCardBack: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

function trimWhitespaceFromMiddle(inputString: string): string {
  const characters = inputString.split(" ");
  let includeCharacter = true;

  const resultArray = characters.filter((char) => {
    if (char.trim() === "") {
      includeCharacter = !includeCharacter;
      return false;
    } else {
      return includeCharacter;
    }
  });

  const resultString = resultArray.join("");
  return resultString;
}

export function PartnerManagementAdminForm({ action, reload, setAction }: Props) {
  const params = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { socialTypes, employeeUnits, departmentUnits } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IFormData>({
    familyName: undefined,
    lastName: undefined,
    firstName: undefined,
    departmentUnitId: undefined,
    departmentSubUnitId: undefined,
    employeeUnitId: undefined,
    email: undefined,
    phone: undefined,
    socialLinks: [],
    email2: undefined,
    phone2: undefined,
    registerNo: undefined,
    identityCardFront: undefined,
    identityCardBack: undefined,
    username: undefined,
    ...((action && action[0] === "update") || (action && action[0] === "detail") ? action[1] : {}),
  });

  const onSubmit = async (values: IFormData) => {
    const item = {
      familyName: values.familyName,
      lastName: trimWhitespaceFromMiddle(values.lastName as string),
      firstName: trimWhitespaceFromMiddle(values.firstName as string),
      departmentUnitId: values.departmentUnitId,
      departmentSubUnitId: values.departmentSubUnitId,
      employeeUnitId: values.employeeUnitId,
      email: values.email,
      phone: values.phone,
      socialLinks: values?.socialLinks?.filter((item: any) => !!item.link) || [],
      email2: values.email2,
      phone2: values.phone2,
      registerNo: values.registerNo,
      identityCardFront: values.identityCardFront,
      identityCardBack: values.identityCardBack,
    };

    setLoading(true);
    try {
      if (action && action[0] !== "update") {
        await PartnerApi.admin_create(`${params.id}`, {
          ...item,
        });
        reload();
        setAction([]);
        Message.success("Амжилттай шинээр админыг хадгаллаа. ");
      } else {
        await PartnerApi.admin_update(`${params.id}`, `${action && action[1]?.id}`, {
          ...item,
        });
        reload();
        setAction([]);
        Message.success("Админыг амжилттай засварлан хадгаллаа. ");
      }
    } catch (err: any) {
      Message.error(err.message || "Хүсэлт амжилтгүй!");
    }
    setLoading(false);
  };

  const icons = (type: string) => {
    switch (type) {
      case "FACEBOOK": {
        return <IconBrandFacebook size={18} />;
      }
      case "VIBER": {
        return <IconPhoneCall size={18} />;
      }
      case "INSTAGRAM": {
        return <IconBrandInstagram size={18} />;
      }
      case "TWITTER": {
        return <IconBrandTwitter size={18} />;
      }
      case "TELEGRAM": {
        return <IconBrandTelegram size={18} />;
      }
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue, errors }) => {
        return (
          <Tabs defaultValue="gallery">
            <Tabs.List>
              <Tabs.Tab value="gallery" leftSection={<IconSettingsAutomation size="1.2rem" />}>
                {action && action[0] === "detail" && "Админы мэдээллийн дэлгэрэнгүй"}
                {action && action[0] === "update" && "Админы мэдээллийг засварлах"}
                {action && action[0] === "new" && "Шинэ админ нэмэх"}
              </Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="gallery" pt="xs">
              <Grid gutter="sm">
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="lg" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField disabled name="username" placeholder="Авто үүснэ" label="Хэрэглэгчийн нэр" />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField disabled name="registeredStaff" placeholder="Авто үүснэ" label="Бүртгэсэн ажилтан" />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <DatePickerField disabled name="createdAt" placeholder="Авто үүснэ" label="Бүртгэсэн огноо, цаг" />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField name="familyName" placeholder="Ургийн овог" label="Ургийн овог" required disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField name="lastName" placeholder="Эцэг, эхийн нэр" label="Эцэг, эхийн нэр" required disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField name="firstName" placeholder="Өөрийн нэр" label="Өөрийн нэр" required disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <SelectField
                    options={(departmentUnits || [])
                      ?.filter((item: any) => item.parentId === null || item.parentId === undefined)
                      .map((c: any) => ({ label: c.name, value: c.id }))}
                    name="departmentUnitId"
                    placeholder="Харъяалах нэгж"
                    label="Харъяалах нэгж"
                    required
                    disabled={action[0] === "detail"}
                    onChange={() => {
                      setFieldValue("employeeUnitId", undefined);
                      setFieldValue("departmentSubUnitId", undefined);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <SelectField
                    disabled={action[0] === "detail"}
                    options={(departmentUnits || [])
                      ?.filter((item: any) => item.parentId === values?.departmentUnitId)
                      ?.map((c: any) => ({ label: c.name, value: c.id }))}
                    name="departmentSubUnitId"
                    placeholder="Харъяалах дэд нэгж"
                    label="Харъяалах дэд нэгж"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <SelectField
                    disabled={action[0] === "detail"}
                    options={(employeeUnits || [])
                      // .filter((item: any) => item.departmentUnitId === values.departmentUnitId)
                      .map((item: any) => {
                        return {
                          label: item.name,
                          value: item.id,
                        };
                      })}
                    name="employeeUnitId"
                    placeholder="Албан тушаал"
                    label="Албан тушаал"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider
                    color="gray"
                    styles={{ label: { fontSize: 14, fontWeight: 500, textTransform: "uppercase" } }}
                    my="lg"
                    label="Холбоо барих мэдээлэл"
                  />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField name="email" placeholder="Оруулна уу" label="И-мэйл хаяг (бүртгэл баталгаажуулах)" required disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField
                    name="phone"
                    disabled={action[0] === "detail"}
                    placeholder="Утасны дугаар (Бүртгэл баталгаажуулах)"
                    label="Утасны дугаар (Бүртгэл баталгаажуулах)"
                    required
                    maxLength={8}
                  />
                </Grid.Col>

                {action[0] === "detail" &&
                  values?.socialLinks &&
                  values?.socialLinks?.map((item: any, index: number) => {
                    return (
                      <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} key={index}>
                        <Input.Wrapper label={<Text tt="capitalize">{item?.type}</Text>}>
                          <Space h={"5px"} />
                          <Anchor>
                            <a href={values?.socialLinks[index]?.link || "#"} target="_blank" rel="noreferrer">
                              <Flex align="center" gap={8}>
                                {icons(item.type)}
                                <div>{item?.type}</div>
                              </Flex>
                            </a>
                          </Anchor>
                        </Input.Wrapper>
                      </Grid.Col>
                    );
                  })}

                {action[0] === "detail" &&
                  socialTypes.map((item: IReference, index: number) => {
                    return (
                      <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} key={index}>
                        <TextField
                          disabled
                          onChangeCustom={() => {
                            setFieldValue(`socialLinks[${index}].type`, item.code);
                          }}
                          icon={icons(item.code)}
                          name={`socialLinks[${index}].link`}
                          placeholder={item.name}
                          label={item.name}
                        />
                      </Grid.Col>
                    );
                  })}
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField name="email2" placeholder="И-мэйл хаяг" label="И-мэйл хаяг" disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField label="Утасны дугаар №2" name="phone2" placeholder="Утасны дугаар №2" maxLength={8} disabled={action[0] === "detail"} />
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider
                    color="gray"
                    styles={{ label: { fontSize: 14, fontWeight: 500, textTransform: "uppercase" } }}
                    my="lg"
                    label="Баталгаа болон иргэний үнэмлэх"
                  />
                </Grid.Col>

                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <TextField
                    name="registerNo"
                    placeholder="Регистрийн дугаар"
                    label="Регистрийн дугаар"
                    required
                    maxLength={10}
                    disabled={action[0] === "detail"}
                  />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <IdentityCardUpload name="identityCardFront" label="Иргэний үнэмлэх - Урд тал" disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                  <IdentityCardUpload name="identityCardBack" label="Иргэний үнэмлэх - Ард тал" disabled={action[0] === "detail"} />
                </Grid.Col>
                <Grid.Col>
                  <Text fz={"sm"} color="dimmed">
                    Админ ажилтан ДАН систем баталгаажуулах үед дээрх иргэний үнэмлэх дэх мэдээлэл таарах ёстой.
                  </Text>
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" my="lg" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Flex justify="space-between" gap="sm">
                    <Button color="gray" variant="outline" onClick={() => setAction([])}>
                      Буцах
                    </Button>
                    <Button loading={loading} type="submit" disabled={action[0] === "detail"}>
                      Хадгалах
                    </Button>
                  </Flex>
                </Grid.Col>
              </Grid>
            </Tabs.Panel>
          </Tabs>
        );
      }}
    </Form>
  );
}
