import { createStyles } from "@mantine/emotion";
import { useViewportSize } from "@mantine/hooks";
import Header from "./header";

export const Layout = ({ children }: { children: any }) => {
  const { classes } = useStyles();
  const { height } = useViewportSize();
  return (
    <div className={classes.container} style={{ minHeight: height }}>
      <Header />
      <div className={classes.child}>{children}</div>
    </div>
  );
};

const useStyles = createStyles(() => ({
  container: {
    backgroundColor: "#040138",
  },
  child: {
    position: "relative",
    zIndex: 1,
    overflow: "hidden",
  },
}));

export default Layout;
