import { Textarea } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label: string;
  disabled?: boolean;
  placeholder: string;
  description?: string;
  required?: boolean;
};

export function TextareaField({ name, label, placeholder, disabled = false, description, required }: Props) {
  const { value, error, onChange } = useField(name);

  return (
    <Textarea
      description={description}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required
      withAsterisk={required}
    />
  );
}
