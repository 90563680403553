import { IUser } from "../interfaces/IUser";
import { AuthApi } from "../apis";
import { useNavigate } from "react-router";
import { HttpHandler } from "../utils/http-handler";
import useSWR from "swr";

type HookTypes = {
  user?: IUser;
  logged: boolean;
  loading: boolean;
  error: HttpHandler;
  reload: () => void;
};

const useUser = (props?: { redirect?: string }): HookTypes => {
  const navigate = useNavigate();
  const { redirect } = props || {};
  const { data, error, isValidating, mutate } = useSWR<IUser>("swr.auth.me", () => AuthApi.me());

  if (!isValidating && redirect && error) {
    navigate("/");
  }

  return {
    user: data,
    logged: !error,
    error: new HttpHandler(error),
    loading: isValidating,
    reload: mutate,
  };
};

export default useUser;
