import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: sectorData) => {
  return httpRequest.post("/prt/back_office/reference/business_sector", data);
};

export const select = async (data: any) => {
  return httpRequest.get("/prt/back_office/reference/business_sector/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/prt/back_office/reference/business_sector", { offset, filter });
};

export type sectorData = {
  parentId?: string;
  bogdCode?: string;
  golomtCode?: string;
  name: string;
  isPublic?: boolean;
};
