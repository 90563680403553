import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/prt/back_office/contract_staff", { offset, filter });
};

export const remove = async (id: string) => {
  return httpRequest.del(`/prt/back_office/contract_staff/${id}`);
};

export const update = async (id: string, data: any | any) => {
  return httpRequest.put(`/prt/back_office/contract_staff/${id}`, data);
};

export const create = async (data: any | any) => {
  return httpRequest.post(`/prt/back_office/contract_staff`, data);
};
