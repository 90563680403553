import { Avatar, Box, Flex, Group, Menu, Modal, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconKey, IconLock, IconLogout, IconPhoto } from "@tabler/icons-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../apis";
import { ReactComponent as EraLogo } from "../../assets/logo.svg";
import noimage from "../../assets/no-image.png";
import { authChange, authMe } from "../../store/Auth";
import { Message } from "../../utils/message";
import { Changepassword } from "../settings/change-password";
import { ChangePin } from "../settings/change-pin";

export const Header = () => {
  const { classes, theme } = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [action, setAction] = React.useState<any>([]);

  const { user } = useSelector((state: any) => state.auth);

  const LogOut = async () => {
    try {
      await AuthApi.logout();

      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      navigate("/auth/login");
    } catch (error: any) {
      Message.error(error?.message);
    }
  };

  return (
    <header className={classes.header}>
      <div className={classes.row}>
        <Flex align="center" gap={8}>
          <EraLogo className={classes.logo} />
          <span>
            <div className={classes.logoText}>DeHUB</div>
            <div className={classes.logoDesc}>платформ</div>
          </span>
        </Flex>
        {user && user?.id ? (
          <Menu position="bottom-end">
            <Menu.Target>
              <UnstyledButton className={classes.user}>
                <Group>
                  <Avatar src={user?.avatar !== null ? user?.avatar : noimage} radius="xl">
                    <IconPhoto />
                  </Avatar>
                  <div style={{ flex: 1 }}>
                    <Text c="white" size="sm" fw={500}>
                      {user?.lastName ? (
                        <>
                          {user?.lastName}.{user?.firstName}
                        </>
                      ) : (
                        "-"
                      )}
                    </Text>
                    <Text c="white" size="xs">
                      {user?.email}
                    </Text>
                  </div>
                  <IconChevronDown size={14} stroke={1.5} fill="white" />
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={() => setAction([true, "pin"])} leftSection={<IconKey size={20} color={theme.colors.yellow[6]} stroke={1.5} />}>
                Пин код
              </Menu.Item>
              <Menu.Item onClick={() => setAction([true, "password"])} leftSection={<IconLock size={20} color={theme.colors.blue[6]} stroke={1.5} />}>
                Нууц үг
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item color="red" onClick={() => LogOut()} leftSection={<IconLogout size={20} stroke={1.5} />}>
                Системээс гарах
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : null}

        <Modal opened={action[0]} onClose={() => setAction([])} centered withCloseButton={false}>
          <Box>
            {action[1] === "pin" && <ChangePin onClose={() => setAction([])} />}
            {action[1] === "password" && <Changepassword onClose={() => setAction([])} />}
          </Box>
        </Modal>
      </div>
    </header>
  );
};

export default Header;

const useStyles = createStyles((theme: any) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyItems: "center",
    alignItems: "center",
    backgroundColor: "#0B1348",
    borderBottom: "4px solid #A81D44",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  logo: {
    display: "flex",
    height: "50px",
    justifyContent: "end",
    alignItems: "end",
    marginBottom: 0,
  },
  subTitle: {
    display: "flex",
    fontSize: 20,
    fontWeight: 600,
    color: "red",
    marginBottom: "-5px",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0 2rem",
    [`@media screen and (max-width: 500px)`]: {
      padding: "0 0.5rem",
    },
  },
  user: {
    color: theme.black,
    "&:hover": {
      backgroundColor: "#00000010",
    },
  },
  userActive: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.white,
  },
  logoText: {
    fontSize: 20,
    fontWeight: 600,
    color: "#bb1f46",
    lineHeight: "18px",
    marginTop: 3,
  },
  logoDesc: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.gray[6],
    textAlign: "end",
  },
}));
