import { Paper, Text, ThemeIcon } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { Link } from "react-router-dom";
import BackgroundPattern from "../../assets/bg-pattern.png";

export function GridCard({ data }: any) {
  const { classes } = useStyles({
    color: data.color,
  });

  if (data.disabled) {
    return (
      <Paper withBorder className={classes.disable}>
        <ThemeIcon size="xl" radius={4} variant="gradient">
          <data.icon size={28} stroke={1.5} />
        </ThemeIcon>
        <Text size="lg" c={"white"} fw={500} mt="md">
          {data.title}
        </Text>
        <Text size="sm" mt="sm" c="white">
          {data.description}
        </Text>
      </Paper>
    );
  }

  return (
    <Link to={`${data.link}/${data.homeLink}`} className={classes.container}>
      <img src={BackgroundPattern} alt="" className={classes.background} />
      <Paper withBorder className={classes.card}>
        <ThemeIcon size="xl" radius={4} variant="gradient">
          <data.icon size={28} stroke={1.5} />
        </ThemeIcon>
        <Text size="lg" c={"white"} fw={500} mt="md">
          {data.title}
        </Text>
        <Text size="sm" mt="sm" c="white">
          {data.description}
        </Text>
      </Paper>
    </Link>
  );
}

const useStyles = createStyles((theme: any, _params: { color: string }) => ({
  card: {
    position: "relative",
    backgroundColor: "#273165",
    cursor: "pointer",
    border: 0,
    borderRadius: 6,
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: "2rem",

    "&:hover": {
      boxShadow: theme.shadows.xs,
      transform: "scale(1.05)",
    },
  },
  background: {
    backgroundRepeat: "repeat",
    position: "absolute",
    width: "100%",
    height: "100%",
    "&:hover": {
      transform: "scale(1.4)",
    },
  },
  container: {
    position: "relative",
  },
  disable: {
    position: "relative",
    backgroundColor: "#ffffff00",
    border: 0,
    borderRadius: 0,
    overflow: "hidden",
    transition: "transform 150ms ease, box-shadow 100ms ease",
    padding: "2rem",

    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      width: 6,
    },
  },
}));
