import { MantineSize, NumberInput } from "@mantine/core";
import { useField } from ".";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  noError?: boolean;
  hideControls?: boolean;
  classNames?: Partial<Record<string, string>> | undefined;
  onChangeCustom?: any;
  size?: MantineSize;
  min?: number;
  max?: number;
  icon?: any;
  iconWidth?: any;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  formatter?: any;
  parser?: any;
  rightSection?: React.ReactNode;
  precision?: any;
};

export function NumberField({
  name,
  label,
  noError = false,
  hideControls = false,
  placeholder,
  disabled = false,
  classNames,
  onChangeCustom,
  size = "sm",
  min,
  max,
  icon,
  iconWidth,
  minLength,
  maxLength,
  required,
  rightSection,
}: Props) {
  const { value, error, onChange } = useField(name);
  return (
    <NumberInput
      minLength={minLength}
      maxLength={maxLength}
      leftSectionWidth={iconWidth}
      leftSection={icon}
      max={max}
      min={min}
      size={size}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      value={value}
      hideControls={hideControls}
      onChange={(v) => {
        onChange(v);
        onChangeCustom && onChangeCustom(v);
      }}
      classNames={classNames}
      styles={{
        error: {
          display: noError ? "none" : "block",
        },
        wrapper: {
          marginBottom: error && noError && 0,
        },
      }}
      required={required}
      rightSection={rightSection}
    />
  );
}
