import { Badge, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";

const sectorData = [
  { id: "information-sector", name: "Үндсэн ангилал", type: "BASIC", level: "1", description: "Үйл ажиллагааны ангилал - хамгийн ерөнхий" },
  { id: "sector", name: "Сектор", type: "SECTOR", level: "2", description: "Үндсэн ангилал дотроос задрах эдийн засгийн сектор" },
  { id: "sub-sector", name: "Дэд сектор", type: "SUB-SECTOR", level: "3", description: "Сектор дотроос задрах эдийн засгийн дэд сектор" },
];

export function Sector() {
  const { classes } = useStyles();
  const ref = useRef<ITableRef>(null);
  const breadcrumb = useBreadcrumb();

  const columns = useHeader({ classes });

  return (
    <PageLayout title="Секторын лавлах" subTitle="Dehub бизнес платформын эдийн засгийн секторын бүртгэл" breadcrumb={breadcrumb}>
      <Table ref={ref} name="partner-management-user-static-sector-data.list" columns={columns} dataSource={sectorData} pagination={false} />
    </PageLayout>
  );
}

const useHeader = ({ classes }: { classes: any }): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Link to={`/partner-management/sector/${String(record.type).toLowerCase()}`}>
          <Badge radius="sm" color="gray.6" className={classes.reference} variant="outline">
            {record.name}
          </Badge>
        </Link>
      );
    },
  },
  {
    title: "Түвшин",
    sorter: true,
    dataIndex: "level",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.level ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Тайлбар",
    sorter: true,
    dataIndex: "level",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.description ?? "-"}
        </Text>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Секторын лавлах жагсаалт",
  },
];

const useStyles = createStyles((theme) => ({
  reference: {
    "&:hover": {
      color: theme.colors.dark[9],
    },
  },
}));
