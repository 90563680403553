import { Badge, Button, Divider, Group, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { optionApi } from "../../apis";
import { IOption } from "../../interfaces/IOption";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { OptionForm } from "./option-form";

export function OptionList() {
  const navigate = useNavigate();
  const [action, setAction] = React.useState<string[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["update", item as any]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await optionApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  Message.success("Амжилттай үйлдвэрлэгчийг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  Message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбарын жагсаалт"
        subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбарын жагсаалт, шинээр үүсгэх, засварлах"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference/product-list")}>
            Буцах
          </Button>,
          <Button
            key={2}
            onClick={() => {
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider mb={10} />
        <Table
          ref={ref}
          name="option.list"
          columns={columns}
          filters={{ query: "", isGoods: `${false}`, isService: `${true}` }}
          loadData={(data) => optionApi.list(data!)}
        />
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered size="md">
        <OptionForm onCancel={onCancel} action={action} reload={reload} />
      </Modal>
    </>
  );
}

// isGoods
// isService

type HeaderProps = {
  onClick: (key: string, record: IOption) => void;
};

const useHeader = ({ onClick }: HeaderProps): ColumnType<IOption>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нээлттэй эсэх",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green" : "red"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Бараа эсэх",
    dataIndex: "isGoods",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isGoods ? "green" : "red"}>
          {record.isGoods ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Үйлчилгээ эсэх",
    dataIndex: "isService",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isService ? "green" : "red"}>
          {record.isService ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "DeHub эсэх",
    dataIndex: "isDehub",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isDehub ? "green" : "red"}>
          {record.isDehub ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateTimeFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Group>
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
            <IconTrash />
          </Button>
        </Group>
      );
    },
  },
];
