import { Grid, Image, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import Background from "../../src/assets/background-blur.svg";
import { GridCard } from "../components/home/card";
import Layout from "../components/home/layout";
import { navigationData } from "../constants/navigation";
import { IAuth } from "../interfaces/IAuth";
import { createStyles } from "@mantine/emotion";

export default function Homepage() {
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { classes } = useStyles();
  return (
    <Layout>
      <Image className={classes.background} src={Background} />
      <div className={classes.container}>
        <div className={classes.row}>
          <div>
            <Text fz={"lg"} c={"white"}>
              Сайн байна уу!
            </Text>
            <Text className={classes.text}>
              {user?.lastName && `${user?.lastName}.`}
              {user?.firstName}
            </Text>
            <div className={classes.description}>
              Та DeHUB платформын Back Office-д амжилттай нэвтэрлээ! Энэ нь DeHUB платформд бүртгэлтэй компаниудын бүртгэл, хяналт, гэрээний харилцаа болон
              админ эрхийн тохиргоо хийх, платформ дээр ашиглагдах лавлах мэдээллүүдийг бүртгэх, тохируулах, дотоод хэрэглэгчдийн бүртгэл, эрхийн тохиргоо
              зэргийг хийх зориулалттай тул зааврын дагуу нягт нямбай, өндөр хариуцлагатай ажиллана уу.
            </div>
          </div>
        </div>
        <Grid className={classes.grid} gutter={48}>
          {navigationData.map((data: any, index: number) => (
            <Grid.Col span={{ xs: 12, sm: 6, lg: 4 }} key={index}>
              <GridCard data={data} />
            </Grid.Col>
          ))}
        </Grid>
      </div>
    </Layout>
  );
}

const useStyles = createStyles(() => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: "3rem",
    paddingBottom: "3rem",
    paddingLeft: "4rem",
    paddingRight: "4rem",
    [`@media screen and (max-width: 500px)`]: {
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    [`@media screen and (min-width: 501px) and (max-width: 768px)`]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    [`@media screen and (min-width: 769px) and (max-width: 1024px)`]: {
      paddingLeft: "2.5rem",
      paddingRight: "2.5rem",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "2rem",
  },
  background: {
    position: "absolute",
    opacity: 0.9,
    top: -500,
    right: -300,
    zIndex: -1,
    maxHeight: "1200px",
    maxWidth: "1200px",
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    marginTop: 8,
    maxWidth: "1000px",
    fontSize: 14,
    color: "white",
  },
  grid: {
    marginTop: 30,
  },
  video: {
    width: "600px",
    backgroundColor: "gray",
    height: 150,
  },
  text: {
    marginTop: -5,
    fontSize: 48,
    fontWeight: 700,
    color: "white",
  },
}));
