import { Button, CloseButton, Divider, Grid, Group, LoadingOverlay } from "@mantine/core";
import React, { useRef } from "react";
import useSWR from "swr";
import * as yup from "yup";
import { categoryApi, itemTypeApi } from "../../apis";
import { ICategory } from "../../interfaces/ICategory";
import { IItemType } from "../../interfaces/IItemType";
import { HttpHandler } from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { DynamicFieldForm, IDynamicRef } from "./dynamic-field-form";

const schema = yup.object({
  itemTypeId: yup.string().required("Заавал бөглөнө!").nullable(),
  name: yup.string().max(100, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  classificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  categoryId: yup.string().required("Заавал бөглөнө!").nullable(),
  subClassificationId: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  name: string;
  itemTypeId: string;
  description: string;
  classificationId: string;
  subClassificationId: string;
  categoryId: string;
  isPublic: boolean;
};

export function SubCategoryForm({ onCancel, reload, title, subTitle }: { onCancel?: () => void; reload: () => void; title: string; subTitle: string }) {
  const [itemType, setItemType] = React.useState<string>("");
  const [classification, setClassification] = React.useState<string>("");
  const [subClassification, setSubClassification] = React.useState<string>("");
  const formRef = useRef<IFormRef<IFormData>>(null);
  const dynamicRef = useRef<IDynamicRef>(null);
  const [loading, setLoading] = React.useState(false);

  const [datas] = React.useState<IFormData>({
    itemTypeId: "",
    classificationId: "",
    subClassificationId: "",
    categoryId: "",
    name: "",
    description: "",
    isPublic: false,
  });

  const { data: itemData } = useSWR(
    `/erp/back_office/item_reference/item_type/select/category`,
    async () => {
      let res = await itemTypeApi.select({ query: "", limit: "" });
      return res;
    },
    {},
  );

  const { data: classificationData } = useSWR(
    `/erp/back_office/item_reference/classification/select/category/${itemType}`,
    async () => {
      if (!itemType) {
        return [];
      }
      let res = await categoryApi.select({
        query: "",
        itemTypeId: itemType,
      });
      return res;
    },
    {},
  );

  const { data: subClassificationData } = useSWR(
    `/erp/back_office/item_reference/subclassification/select/category/${itemType}/${classification}`,
    async () => {
      if (!classification && !classification) {
        return [];
      }
      let res = await categoryApi.select({
        query: "",
        itemTypeId: itemType,
        parentId: classification,
      });
      return res;
    },
    {},
  );

  const { data: categoryData } = useSWR(
    `/erp/back_office/item_reference/category/select/category/${itemType}/${subClassification}`,
    async () => {
      if (!itemType && !subClassification) {
        return [];
      }
      let res = await categoryApi.select({
        query: "",
        itemTypeId: itemType,
        parentId: subClassification,
      });
      return res;
    },
    {},
  );

  const onSubmit = async (value: any) => {
    let valid = await dynamicRef.current?.submit();

    if (!valid) return Message.error("Динамик талбарууд алдаатай байна!");
    try {
      setLoading(true);
      await categoryApi.createSub({
        itemTypeId: value.itemTypeId,
        parentId: value.categoryId,
        name: value.name,
        description: value.description,
        isPublic: value.isPublic,
        categoryFields: (valid?.fields || [])
          .sort((a, b) => a.sort - b.sort)
          .map((field) => ({
            name: field.name!,
            type: field.type!,
            numberUnit: field.numberUnit!,
            values: (field.values || [])?.map((value: string) => ({
              name: value,
              isDefault: value === field.defaultValueId ? true : false,
            })),
          })),
      });
      Message.success("Таны хүсэлт амжилттай!");
      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />
      <Divider mb="xl" />
      <Form ref={formRef} validationSchema={schema} onSubmit={onSubmit} initialValues={datas}>
        {({ setFieldValue, values }) => {
          return (
            <Grid>
              <Grid.Col span={4}>
                <SelectField
                  name="itemTypeId"
                  label="Нэр төрөл"
                  placeholder="Нэр төрөл"
                  options={(itemData || []).map((c: IItemType) => ({ label: c.name, value: c.id }))}
                  onChange={(e) => {
                    setFieldValue("classificationId", undefined);
                    setFieldValue("subClassificationId", undefined);
                    setFieldValue("categoryId", undefined);
                    setItemType(e as string);
                  }}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="classificationId"
                  label="Ангилал"
                  placeholder="Ангилал"
                  options={(classificationData || []).map((c: ICategory) => ({ label: c.name, value: c.id }))}
                  onChange={(e) => {
                    setFieldValue("subClassificationId", undefined);
                    setFieldValue("categoryId", undefined);
                    setClassification(e as string);
                  }}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  name="subClassificationId"
                  label="Дэд ангилал"
                  placeholder="сонгох"
                  options={(subClassificationData || []).map((c: ICategory) => ({ label: c.name, value: c.id }))}
                  onChange={(e) => {
                    setFieldValue("categoryId", undefined);
                    setSubClassification(e as string);
                  }}
                  required
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <SelectField
                  name="categoryId"
                  label="Категори"
                  placeholder="Категори"
                  options={(categoryData || []).map((c: ICategory) => ({ label: c.name, value: c.id }))}
                  required
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="name" label="Дэд категори нэр" placeholder="Дэд категори нэр" required />
              </Grid.Col>
              <Grid.Col span={4}>
                <CheckboxField name="isPublic" label="Нээлттэй" />
              </Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>

      <Divider my="lg" />

      <DynamicFieldForm ref={dynamicRef} />

      <Divider />

      <Group mt={20}>
        <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
          Болих
        </Button>
        <Button key={2} onClick={() => formRef.current?.submit()}>
          Хадгалах
        </Button>
      </Group>
    </FormLayout>
  );
}

// Дэд категори
// Барааны нэмэлт мэдээлэл
// Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбарын жагсаалт
