import { IMerchantFormData } from "../components/merchant/form";
import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: IMerchantFormData) => {
  return httpRequest.post("/pay/back_office/qpay/merchant", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/pay/back_office/qpay/merchant", { offset, filter });
};

export const select = async (data: { query: string; limit?: number }) => {
  return httpRequest.get("/pay/back_office/qpay/merchant/partner_select", data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/pay/back_office/qpay/merchant/partner/${id}`);
};

export const businessselect = async (data: { partnerId: string; query?: string; limit?: number }) => {
  return httpRequest.get("/pay/back_office/qpay/merchant/business_select", data);
};
