import { Button, Tabs } from "@mantine/core";
import React from "react";
import { PartnerManagementBankAccountList } from "../../../components/bank-account/list";
import { PageLayout } from "../../../components/layout";
import { PartnerManagementMerchantList } from "../../../components/merchant/list";

export function PartnerManagementMerchant() {
  const breadcrumbs = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const [activeTab, setActiveTab] = React.useState<string | null>("MERCHANT");

  return (
    <PageLayout
      title="QR мерчантаар бүртгүүлсэн партнерууд"
      subTitle="QR-р төлбөр тооцоо хийхээр холболт хийлгэсэн партнерийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Button key={0} onClick={() => setAction(["ADD"])}>
          Мерчант бүртгэх
        </Button>,
      ]}>
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="MERCHANT" pb="md">
            Мерчант жагсаалт
          </Tabs.Tab>
          <Tabs.Tab value="ACCOUNT" pb="md">
            Данс жагсаалт
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="MERCHANT" pt="xs">
          <PartnerManagementMerchantList
            action={action}
            onSuccess={() => {
              setActiveTab("MERCHANT");
              setAction([]);
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel value="ACCOUNT" pt="xs">
          <PartnerManagementBankAccountList />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "QPay мерчант",
  },
];
