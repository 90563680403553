import { ActionIcon, Avatar, Badge, Box, Button, Divider, Flex, Input, Modal, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { useDebouncedValue } from "@mantine/hooks";
import { IconSearch, IconX } from "@tabler/icons-react";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { userApi } from "../../../apis";
import noimage from "../../../assets/no-image.png";
import { PageFilter, PageLayout } from "../../../components/layout";
import { ColumnType, ITableRef, Table } from "../../../components/table";
import { PartnerManagementUserForm } from "../../../components/user/form";
import { IAllUser } from "../../../interfaces/IAllUser";
import { IGeneral } from "../../../interfaces/IGeneral";
import { IReference } from "../../../models/General";
import { dateSecFormat } from "../../../utils/date";

function PartnerManagementUser(): JSX.Element {
  const { classes } = useStyles();
  const ref = useRef<ITableRef>(null);
  const breadcrumb = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);
  const { userRegisterStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const [query, setQuery] = React.useState("");
  const [queryDebounced] = useDebouncedValue(query, 400);

  const columns = useHeader({
    classes,
    userRegisterStatus,
    onClick: (key, record) => {
      switch (key) {
        case "business": {
          setAction(["BUSINESS", record as any]);
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  return (
    <>
      <PageLayout title="Хэрэглэгчийн жагсаалт" subTitle="DE-HUB бизнес платформд бүртгэлтэй нийт хэрэглэгчдийн жагсаалт" breadcrumb={breadcrumb}>
        <Divider mb={5} />

        <PageFilter
          left={[
            <Input
              key={1}
              value={query}
              w={400}
              onChange={(e) => setQuery(e.currentTarget.value)}
              placeholder="Хайлт хийх"
              leftSection={<IconSearch size={18} />}
              rightSection={
                <ActionIcon
                  onClick={() => {
                    setQuery("");
                  }}>
                  <IconX size={14} />
                </ActionIcon>
              }
            />,
          ]}
        />
        <Table ref={ref} name="partner-management-user.list" filters={{ query: queryDebounced }} columns={columns} loadData={(data) => userApi.list(data!)} />
      </PageLayout>
      <Modal opened={action[0] === "BUSINESS"} onClose={() => setAction([])} withCloseButton={false} centered size="70%">
        <PartnerManagementUserForm action={action} onCancel={onCancel} />
      </Modal>
    </>
  );
}

export { PartnerManagementUser };

const useHeader = ({
  userRegisterStatus,
  onClick,
}: {
  classes: any;
  userRegisterStatus: IReference[];
  onClick: (key: string, record: IAllUser) => void;
}): ColumnType<IAllUser>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "avatar",
    render: (record) => {
      return <Avatar src={record.avatar !== null ? record.avatar : noimage} alt="" color="indigo" />;
    },
  },
  {
    title: "Username",
    sorter: true,
    dataIndex: "username",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.username ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Овог",
    sorter: true,
    dataIndex: "lastName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.lastName ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.firstName ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Имэйл",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.email ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.phone ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Бизнесийг харах",
    sorter: true,
    dataIndex: "businessCount",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.businessCount ?? 0}
        </Text>
      );
    },
  },
  {
    title: "Бизнесийг харах",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <>
          {record?.businessCount > 0 ? (
            <Button variant="default" radius="sm" onClick={() => onClick("business", record)}>
              {"Дэлгэрэнгүй"}
            </Button>
          ) : (
            "-"
          )}
        </>
      );
    },
  },
  {
    title: "Бүртгэсэн ажилтан",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar src={record?.regUser?.avatar !== null ? record?.regUser?.avatar : noimage} />
          <div>
            <Text size="sm" fw={500} c="#44566C">
              {record?.regUser?.lastName ?? "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" fw={500} c="#141517">
                {record?.regUser?.firstName}
              </Text>
            </Flex>
          </div>
        </Box>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэлийн статус",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            background: "white",
            borderColor: (userRegisterStatus ?? []).find((item: IReference) => item.code === record?.registerStatus)?.color,
            color: (userRegisterStatus ?? []).find((item: IReference) => item.code === record?.registerStatus)?.color,
          }}>
          {(userRegisterStatus ?? []).find((item: IReference) => item.code === record?.registerStatus)?.name ?? "Амжилтгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Веб хэрэглэгч эсэх",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isSysUser ? "green" : "red"}>
          {record.isSysUser ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Апп хэрэглэгч эсэх",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isAppUser ? "green" : "red"}>
          {record.isAppUser ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
];

const useStyles = createStyles((theme) => ({
  item: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: `1px solid #44566C`,
    borderRadius: theme.radius.sm,
    color: "#44566C",
    height: 23,

    "&:hover": {
      boxShadow: theme.shadows.md,
      transform: "scale(1.05)",
    },
  },
}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Хэрэглэгчийн жагсаалт",
  },
];
