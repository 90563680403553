import { Avatar, Badge, Box, Button, Flex, Group, Input, Text, Tooltip } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconEdit, IconInfoSquare, IconSearch } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { PartnerApi } from "../../apis";
import noimage from "../../assets/no-image.png";
import { IGeneral } from "../../interfaces/IGeneral";
import { IPartner } from "../../interfaces/IPartner";
import { IReference } from "../../models/General";
import { Dialog } from "../../utils/confirm-modal";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { PageFilter } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { Tag } from "../tags";

export function PartnerManagementPartnerCitizenList() {
  const navigate = useNavigate();
  const [query, setQuery] = React.useState("");
  const [queryDebounced] = useDebouncedValue(query, 400);
  const { departmentUnits, legalEntityTypes, partnerTypes, partnerRegisterStatus, contractStatus, partnerStatus, partnerRegistrationType } = useSelector(
    (state: { general: IGeneral }) => state.general,
  );
  const ref = React.useRef<ITableRef>(null);

  const columns = useHeader({
    legalEntityTypes,
    departmentUnits,
    partnerTypes,
    partnerRegisterStatus,
    contractStatus,
    partnerStatus,
    partnerRegistrationType,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          navigate(`/partner-management/partner/CITIZEN/edit/${item.id}/1`);
          break;
        }
        case "details": {
          navigate(`/partner-management/partner/CITIZEN/detail/${item.id}/1`);
          break;
        }
        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                try {
                  await PartnerApi.remove(`${item?.id}`);
                  reload();
                  Message.success("Ажилтан амжилттай устгагдлаа");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <>
      <PageFilter
        left={[
          <Input
            leftSectionWidth={120}
            onChange={(e) => setQuery(e.currentTarget.value)}
            placeholder="хайх"
            rightSection={<IconSearch size={18} style={{ display: "block", opacity: 0.5 }} />}
            leftSection={
              <Group w={"calc(100% - 20px)"} align="center">
                <Text size="sm" fw={450} c="#44566C">
                  {"Хайлт хийх"}
                </Text>
                <Text>:</Text>
              </Group>
            }
            key={1}
          />,
        ]}
      />
      <Table
        ref={ref}
        name="partner-management-partner-citizen.list"
        columns={columns}
        filters={{ query: queryDebounced, type: "CITIZEN" }}
        loadData={(data) => PartnerApi.list(data!)}
      />
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: IPartner) => void;
  departmentUnits: any;
  legalEntityTypes: IReference[];
  partnerTypes: IReference[];
  partnerRegisterStatus: IReference[];
  contractStatus: IReference[];
  partnerStatus: IReference[];
  partnerRegistrationType: IReference[];
};

const useHeader = ({
  onClick,
  legalEntityTypes,
  partnerTypes,
  partnerRegisterStatus,
  contractStatus,
  partnerStatus,
  partnerRegistrationType,
}: HeaderProps): ColumnType<any>[] => [
  {
    title: "Үйлдэл",
    align: "left",
    sorter: false,
    dataIndex: "sku",
    render: (record) => {
      return (
        <Flex gap="sm">
          <Tooltip label="Засварлах" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Дэлгэрэнгүй" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
              <IconInfoSquare />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "ТТД",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.refCode || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.businessName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Төрөл",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Badge variant="outline" size="md" radius="sm" color={record.type === "CITIZEN" ? "green" : "blue"}>
        {partnerTypes.find((item: IReference) => item.code === record?.type)?.name || "-"}
      </Badge>
    ),
  },
  {
    title: "Админ нэр",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Group>
          <div>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" fw={500} c="#141517">
                {record?.admin?.firstName || "-"}
              </Text>
            </Flex>
            <Text size="sm" fw={500} c="#44566C">
              {record?.email || "-"}
            </Text>
          </div>
        </Group>
      );
    },
  },
  {
    title: "ААН",
    sorter: true,
    dataIndex: "legalEntityType",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" style={{ width: 200 }}>
          {legalEntityTypes.find((item: IReference) => item.code === record.legalEntityType)?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Buyer эсэх",
    sorter: true,
    dataIndex: "isBuyer",
    render: (record) => {
      return record?.isBuyer ? (
        <Badge variant="outline" size="md" radius="sm" color="green">
          Тийм
        </Badge>
      ) : (
        <Badge variant="outline" size="md" radius="sm" color="red">
          Үгүй
        </Badge>
      );
    },
  },
  {
    title: "Supplier эсэх",
    sorter: true,
    dataIndex: "isSupplier",
    render: (record) => {
      return record?.isSupplier ? (
        <Badge variant="outline" size="md" radius="sm" color="green">
          Тийм
        </Badge>
      ) : (
        <Badge variant="outline" size="md" radius="sm" color="red">
          Үгүй
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэлийн статус",
    sorter: true,
    dataIndex: "registerStatus",
    render: (record) => {
      return (
        <Tag
          color={partnerRegisterStatus.find((item: IReference) => item.code === record.registerStatus)?.color || "gray"}
          name={partnerRegisterStatus.find((item: IReference) => item.code === record.registerStatus)?.name || "-"}
        />
      );
    },
  },
  {
    title: "Төлөв",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return (
        <Badge
          size="md"
          radius="sm"
          variant="outline"
          style={{
            color: `${partnerStatus.find((item: IReference) => item.code === record.partnerStatus)?.color}`,
            border: `${partnerStatus.find((c: any) => c.code === record.partnerStatus)?.color} 1px solid`,
          }}>
          {partnerStatus.find((item: IReference) => item.code === record.partnerStatus)?.name}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return (
        <Box style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Avatar src={record?.regUser?.avatar !== null ? record?.regUser?.avatar : noimage} />
          <div>
            <Text size="sm" fw={500} c="#44566C">
              {record?.regUser?.lastName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" fw={500} c="#141517">
                {record?.regUser?.firstName || "-"}
              </Text>
            </Flex>
          </div>
        </Box>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "isActive",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэл төрөл",
    sorter: true,
    dataIndex: "registerType",
    render: (record) => {
      return (
        <Badge size="md" radius="sm" variant="outline" color="blue">
          {partnerRegistrationType.find((item: IReference) => item.code === record.registrationType)?.name}
        </Badge>
      );
    },
  },
];
