import { Button, CloseButton, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import * as yup from "yup";
import { InvoiceApi } from "../../apis";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { number, percent } from "../../utils";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

type IFormData = {
  fullName: string;
  condition: string;
  configType: string;
  expireDayCount: number;
  advancePercent: string;
  confirmationDay: string;
  nowDate: any;
  paymentTerm: string;
  isMain: boolean;
  isOpen: boolean;
};

type Props = {
  action: any;
  setAction: any;
  reload: any;
};

const schema = yup.object({
  condition: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  configType: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  advancePercent: yup.string().when(["condition", "configType"], (condition, configType) => {
    if (typeof condition === "string" && condition === "COD" && typeof configType === "string" && configType === "CIA") {
      return yup.string().matches(percent, "Утга шалгана уу!").required("Заавал бөглөнө үү!").nullable();
    }
    return yup.string().nullable();
  }),
  expireDayCount: yup.string().when(["condition"], (condition) => {
    if (typeof condition === "string" && condition === "INV_CONFIG") {
      return yup.string().matches(number, "Утга шалгана уу! (Хамгийн ихтэй 90 || Хамгийн багадаа 1)").required("Заавал бөглөнө үү!").nullable();
    }
    return yup.string().nullable();
  }),
  confirmationDay: yup.string().when(["condition", "configType"], (condition, configType) => {
    if (typeof condition === "string" && condition === "COD" && typeof configType === "string" && configType === "CIA") {
      return yup.string().matches(number, "Утга шалгана уу! (Хамгийн ихтэй 90 || Хамгийн багадаа 1)").required("Заавал бөглөнө үү!").nullable();
    }
    if (typeof condition === "string" && condition === "COD" && typeof configType === "string" && configType === "CBD") {
      return yup.string().matches(number, "Утга шалгана уу! (Хамгийн ихтэй 90 || Хамгийн багадаа 1)").required("Заавал бөглөнө үү!").nullable();
    }
    return yup.string().nullable();
  }),
});

export function PaymentTermForm({ action, setAction, reload }: Props) {
  const params = useParams();
  const nowMoment = new Date();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { paymentTermConditions, paymentTermConfigTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    condition: "",
    configType: "",
    expireDayCount: "",
    advancePercent: "",
    confirmationDay: "",
    fullName: `${user?.lastName[0]}. ${user?.firstName}`,
    nowDate: dateSecFormat(nowMoment),
    paymentTerm: "Нэхэмжлэх",
    isMain: false,
    isOpen: false,

    ...(action && action[0] === "detail" ? action[1] : {}),
  });

  console.log(action[0]);

  const title = (val: string) => {
    switch (val) {
      case "COD": {
        return "Бэлэн төлөх нөхцөл";
      }
      case "INV_CONFIG": {
        return "Нэхэмжлэхээр төлөх нөхцөл";
      }
      case "AR_NET30": {
        return "Авлага";
      }
    }
  };

  const subTitle = (val: string) => {
    switch (val) {
      case "COD": {
        return "Бэлэн төлөх нөхцөлийг шинээр үүсгэх";
      }
      case "INV_CONFIG": {
        return "Нэхэмжлэхээр төлөх нөхцөлийг шинээр үүсгэх";
      }
      case "AR_NET30": {
        return "Авлага шинээр үүсгэх";
      }
    }
  };

  const subTitleDetail = (val: string) => {
    switch (val) {
      case "COD": {
        return "Бэлэн төлөх нөхцөлийн дэлгэрэнгүй мэдээлэл";
      }
      case "INV_CONFIG": {
        return "Нэхэмжлэхээр төлөх нөхцөлийн дэлгэрэнгүй мэдээлэл";
      }
      case "AR_NET30": {
        return "Авлага дэлгэрэнгүй мэдээлэл";
      }
    }
  };

  const onSubmit = async (value: IFormData) => {
    const formData = {
      condition: value?.condition,
      configType: value?.configType,
      expireDayCount: value?.expireDayCount,
      advancePercent: value?.advancePercent,
      confirmationDay: value?.confirmationDay,
      isMain: value?.isMain,
      isOpen: value?.isOpen,
    };

    setLoading(true);
    try {
      await InvoiceApi.create(formData);
      Message.success("Амжилттай нэхэмлэх төлөх тохиргоо хийсэн.");
      reload();
      setAction([]);
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout
            title={`${title(params?.type as string)}`}
            subTitle={action && action[0] === "new" ? `${subTitle(params?.type as string)}` : `${subTitleDetail(params?.type as string)}`}
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
            <Divider color="gray" styles={{ label: { fontSize: 18, fontfw: 400 } }} my="xs" />
            <LoadingOverlay visible={loading} />
            <Grid gutter="sm">
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                <TextField name="fullName" placeholder="Огноо" label="Тохиргоо хийсэн ажилтан:" disabled />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                <TextField name="nowDate" placeholder="Огноо" label="Тохиргоо хийсэн огноо:" disabled />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                <TextField name="paymentTerm" placeholder="Огноо" label="Төлбөрийн нөхцөл:" disabled />
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <SelectField
                    name="condition"
                    placeholder="сонгох"
                    label="Төлөх хугацааны тохиргоо:"
                    options={(paymentTermConditions || [])?.filter((c: any) => c?.code === params?.type)?.map((c: any) => ({ label: c?.name, value: c?.code }))}
                    onChange={() => {
                      setFieldValue("configType", undefined);
                      setFieldValue("advancePercent", undefined);
                      setFieldValue("confirmationDay", undefined);
                      setFieldValue("expireDayCount", undefined);
                    }}
                    required
                  />
                ) : (
                  <Input.Wrapper label="Төлөх хугацааны тохиргоо:">
                    <Text size="lg" fw={500} c="#44566C">
                      {(paymentTermConditions || [])?.find((c: any) => c?.code === values?.condition)?.name}
                    </Text>
                  </Input.Wrapper>
                )}
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <SelectField
                    name="configType"
                    placeholder="сонгох"
                    label="Захиалга баталгаажуулах үе:"
                    options={(paymentTermConfigTypes || [])
                      ?.filter((c: any) => (values?.condition === "INV_CONFIG" ? c?.code === "INV_NET_X" : c?.condition === values?.condition))
                      ?.map((c: any) => ({ label: c?.text, value: c?.code }))}
                    onChange={(e) => {
                      setFieldValue("expireDayCount", undefined);
                      setFieldValue("advancePercent", undefined);
                      setFieldValue("confirmationDay", undefined);
                    }}
                    disabled={!values?.condition}
                    required
                  />
                ) : (
                  <Input.Wrapper label="Захиалга баталгаажуулах үе:">
                    <Text size="lg" fw={500} c="#44566C">
                      {(paymentTermConfigTypes || [])?.find((c: any) => c?.code === values?.configType)?.text}
                    </Text>
                  </Input.Wrapper>
                )}
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <TextField
                    name="expireDayCount"
                    placeholder="Өдөр"
                    label="Expire day count:"
                    required={values?.condition === "INV_CONFIG" ? true : false}
                    disabled={values?.condition === "INV_CONFIG" ? false : true}
                  />
                ) : (
                  <Input.Wrapper label="Expire day count:">
                    <Text size="lg" fw={500} c="#44566C">
                      {values?.condition === "INV_CONFIG" ? values?.expireDayCount : "0"}
                    </Text>
                  </Input.Wrapper>
                )}
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <TextField
                    name="advancePercent"
                    placeholder="Хувь"
                    label="Advance percent:"
                    disabled={values?.condition === "COD" && values?.configType === "CIA" ? false : true}
                    required={values?.condition === "COD" && values?.configType === "CIA" ? true : false}
                  />
                ) : (
                  <Input.Wrapper label="Advance percent:">
                    <Text size="lg" fw={500} c="#44566C">
                      {values?.condition === "COD" && values?.configType === "CIA" ? values?.advancePercent : "0"}
                    </Text>
                  </Input.Wrapper>
                )}
              </Grid.Col>
              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <TextField
                    name="confirmationDay"
                    placeholder="Баталгаажуулах"
                    label="Confirmation day:"
                    disabled={
                      (values?.condition === "COD" && values?.configType === "CIA") || (values?.condition === "COD" && values?.configType === "CBD")
                        ? false
                        : true
                    }
                    required={
                      (values?.condition === "COD" && values?.configType === "CIA") || (values?.condition === "COD" && values?.configType === "CBD")
                        ? true
                        : false
                    }
                  />
                ) : (
                  <Input.Wrapper label="Confirmation day:">
                    <Text size="lg" fw={500} c="#44566C">
                      {(values?.condition === "COD" && values?.configType === "CIA") || (values?.condition === "COD" && values?.configType === "CBD")
                        ? values?.confirmationDay
                        : "0"}
                    </Text>
                  </Input.Wrapper>
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <CheckboxField name="isMain" label="Стандарт үндсэн сонголт">
                    {values?.isMain ? (
                      <Text size="sm" fw={500} c="#44566C">
                        {"Тийм"}
                      </Text>
                    ) : (
                      <Text size="sm" fw={500} c="#44566C">
                        {"Үгүй"}
                      </Text>
                    )}
                  </CheckboxField>
                ) : (
                  <Input.Wrapper label="Стандарт үндсэн сонголт">
                    {values?.isMain ? (
                      <Text size="lg" fw={500} c="#44566C">
                        {"Тийм"}
                      </Text>
                    ) : (
                      <Text size="lg" fw={500} c="#44566C">
                        {"Үгүй"}
                      </Text>
                    )}
                  </Input.Wrapper>
                )}
              </Grid.Col>

              <Grid.Col span={{ sm: 12, md: 6, lg: 4 }}>
                {action && action[0] === "new" ? (
                  <CheckboxField name="isOpen" label="DeHUB Network-д нээлттэй">
                    {values?.isOpen ? (
                      <Text size="sm" fw={500} c="#44566C">
                        {"Тийм"}
                      </Text>
                    ) : (
                      <Text size="sm" fw={500} c="#44566C">
                        {"Үгүй"}
                      </Text>
                    )}
                  </CheckboxField>
                ) : (
                  <Input.Wrapper label="DeHUB Network-д нээлттэй">
                    {values?.isOpen ? (
                      <Text size="lg" fw={500} c="#44566C">
                        {"Тийм"}
                      </Text>
                    ) : (
                      <Text size="lg" fw={500} c="#44566C">
                        {"Үгүй"}
                      </Text>
                    )}
                  </Input.Wrapper>
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider color="gray" mt={10} mb={10} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Flex justify="space-between" gap="sm">
                  <Button variant="outline" onClick={() => setAction([])}>
                    Буцах
                  </Button>
                  {action && action[0] !== "detail" && (
                    <Button loading={loading} type="submit">
                      Хадгалах
                    </Button>
                  )}
                </Flex>
              </Grid.Col>
            </Grid>
          </FormLayout>
        );
      }}
    </Form>
  );
}
