import { Divider, Tabs } from "@mantine/core";
import React from "react";
import { PartnerManagementAdminBusinessList } from "../../../components/admin/business-list";
import { PartnerManagementAdminCompanyList } from "../../../components/admin/company-list";
import { PageLayout } from "../../../components/layout";

function PartnerManagementAdminLists(): JSX.Element {
  const breadcrumbs = useBreadcrumb();
  const [activeTab, setActiveTab] = React.useState<string | null>("COMPANY");

  return (
    <PageLayout title="CP админы жагсаалт" subTitle="Партнерт бүртгэлтэй админы жагсаалт" breadcrumb={breadcrumbs}>
      <Divider mb="lg" />
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List>
          <Tabs.Tab value="COMPANY" pb="md">
            Хуулийн этгээд
          </Tabs.Tab>
          <Tabs.Tab value="BUSINESS" pb="md">
            Хувиараа бизнес эрхлэгч
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="COMPANY" pt="xs">
          <PartnerManagementAdminCompanyList />
        </Tabs.Panel>
        <Tabs.Panel value="BUSINESS" pt="xs">
          <PartnerManagementAdminBusinessList />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
}

export { PartnerManagementAdminLists };

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Админ жагсаалт",
  },
];
