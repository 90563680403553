import { Box, Button, FileButton, Image, Input, LoadingOverlay } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconId } from "@tabler/icons-react";
import React from "react";
import { MediaApi } from "../../apis";
import { Message } from "../../utils/message";
import { useField } from "../form";

export type IFile = {
  uploading: boolean;
  url?: string;
  file: Blob | null;
};

type Props = {
  value?: string | undefined | null;
  error?: string;
  onChange?: (file: IFile) => void;
  disabled?: boolean;
};

type FeildProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

function ImageFieldIdentity({ onChange, error, value, disabled }: Props) {
  const { classes, cx } = useStyle();
  const [file, setFile] = React.useState<IFile>();
  const resetRef = React.useRef<() => void>(null);

  const onFileUpload = (blob: Blob | null) => {
    if (!blob) return;

    (async () => {
      try {
        const form = new FormData();
        form.append("file", blob);

        const res = await MediaApi.uploadImage(form);

        if (onChange)
          onChange({
            file: blob,
            url: res.url,
            uploading: false,
          });

        setFile({
          file: blob,
          url: res.url,
          uploading: false,
        });
      } catch (err: any) {
        Message.error(err?.message);
      }
    })();

    setFile({
      file: blob,
      uploading: true,
    });
  };

  React.useEffect(() => {
    value &&
      setFile({
        file: null,
        url: value,
        uploading: false,
      });
  }, [value]);

  return (
    <div className={classes.multiImageUpload}>
      <FileButton disabled={disabled} resetRef={resetRef} onChange={onFileUpload} accept="image/png,image/jpeg">
        {(props) =>
          file ? (
            <Box className={cx(classes.imageBox)} {...props}>
              <Image className={classes.image} src={file.file ? URL.createObjectURL(file.file) : file.url} alt="Random unsplash image" />
              <LoadingOverlay visible={file.uploading} opacity={0.3} loaderProps={{ size: "sm" }} />
            </Box>
          ) : (
            <Button w={400} h={250} variant="light" className={cx(classes.uploadButton, error && "error")} {...props}>
              <IconId size={28} />
            </Button>
          )
        }
      </FileButton>
    </div>
  );
}

export function IdentityCardUpload({ name, label, disabled }: FeildProps) {
  const { value, error, onChange } = useField(name);

  return (
    <Input.Wrapper label={label} error={error} required>
      <ImageFieldIdentity disabled={disabled} value={value} onChange={(file: IFile) => onChange(file.url)} error={error} />
    </Input.Wrapper>
  );
}

const useStyle = createStyles((theme, _params) => ({
  multiImageUpload: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 5,
    gap: 15,
  },
  imageBox: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: 400,
    height: 250,
    cursor: "pointer",
    borderRadius: 4,
    overflow: "hidden",
    "&.selected": {
      borderWidth: 3,
      borderStyle: "solid",
      borderColor: theme.colors.indigo[6],
    },
    "&:hover $actionBox": {
      display: "flex!important",
    },
  },
  image: {
    position: "absolute",
  },
  uploadButton: {
    width: 80,
    height: 80,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "&.error": {
      backgroundColor: theme.colors.red[1],
      color: theme.colors.red[6],
    },
  },
}));
