import { Button, Divider, Tabs } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router";
import { PageLayout } from "../../../components/layout";
import { PartnerManagementPartnerCitizenList } from "../../../components/partner/partner-citizen-list";
import { PartnerManagementPartnerEntityList } from "../../../components/partner/partner-entity.list";

export function PartnerManagementPartnerLists() {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string | null>("COMPANY");

  return (
    <PageLayout
      title="Партнер жагсаалт"
      subTitle="Хуулийн этгээдээр бүртгэгдсэн партнерийн жагсаалт"
      breadcrumb={breadcrumbs}
      extra={[
        <Button
          key={0}
          onClick={() => {
            navigate(`/partner-management/partner/${activeTab}/new`);
          }}>
          Шинэ партнер
        </Button>,
      ]}>
      <Divider mb="lg" />
      <Tabs value={activeTab} onChange={setActiveTab}>
        <Tabs.List mb={"md"}>
          <Tabs.Tab value="COMPANY" pb="md">
            Хуулийн этгээд
          </Tabs.Tab>
          <Tabs.Tab value="CITIZEN" pb="md">
            Иргэн
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="COMPANY" pt="md">
          <PartnerManagementPartnerEntityList />
        </Tabs.Panel>
        <Tabs.Panel value="CITIZEN" pt="md">
          <PartnerManagementPartnerCitizenList />
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
}

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Партнер жагсаалт",
  },
];
