import { IFilter } from "./../interfaces/IFilter";
import { HttpRequest } from "../utils";
import { IFieldType } from "../models/General";

const httpRequest = new HttpRequest();

export const create = async (data: { itemTypeId?: string; parentId?: string; name: string; description: string; type: string; isPublic: boolean }) => {
  return httpRequest.post("/erp/back_office/item_reference/category", data);
};

export const sub = async (data: { name: string; description: string; itemTypeId: string; parentId?: string }) => {
  return httpRequest.post("/erp/back_office/item_reference/category/sub", data);
};

export const createSub = async (data: {
  name: string;
  description: string;
  itemTypeId?: string;
  parentId?: string;
  isPublic?: boolean;
  categoryFields: {
    name: string;
    type: IFieldType;
    numberUnit: string;
    values: {
      name: string;
      isDefault: boolean;
    }[];
  }[];
}) => {
  return httpRequest.post("/erp/back_office/item_reference/category/sub", data);
};

export const select = async (data?: { type?: string; itemTypeId?: string; query: string; parentId?: string }) => {
  return httpRequest.get("/erp/back_office/item_reference/category/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/back_office/item_reference/category", { offset, filter });
};

export const update = async (id: string, data: { name: string; description: string; type?: string; itemTypeId?: string; parentId?: string }) => {
  return httpRequest.put(`/erp/back_office/item_reference/category/${id}`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/back_office/item_reference/category/${id}`);
};

export const get = async (id: string) => {
  return httpRequest.get(`/erp/back_office/item_reference/category/${id}`);
};

export const suUpdate = async (id: string, data: { name: string; description: string; isPublic: boolean }) => {
  return httpRequest.put(`/erp/back_office/item_reference/category/sub/${id}`, data);
};
