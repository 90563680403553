import { Button, CloseButton, Divider, Flex, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  name: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  values: yup.array().of(yup.string()).min(1, "Заавал бөглөнө!").required("Заавал бөглөнө!"),
});

type IFormData = {
  name: string;
  isPublic: boolean;
  isGoods: boolean;
  isService: boolean;
  values: [];
};

export function OptionForm({ onCancel, action, reload }: { onCancel?: () => void; action: any; reload: () => void }) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    name: "",
    isPublic: false,
    isGoods: false,
    isService: false,
    values: [],
  });

  const onSubmit = () => {};

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values }) => {
        return (
          <FormLayout
            title={action && action[0] === "update" ? "Үйлдвэрлэгч засварлах" : "Үйлдвэрлэгч нэмэх"}
            subTitle="Үйлдвэрлэгч мэдээлэл "
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              <Divider />
              <TextField name="name" label="Нэр" placeholder="Нэр" required />
              <CheckboxField name="isPublic" label="Нээлттэй" />
              <CheckboxField name="isGoods" label="Бараа" />
              <CheckboxField name="isService" label="Үйлчилгээ" />
              <Divider mb={10} />
            </Flex>
            <Flex justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
