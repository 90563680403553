import { Button, Grid, Group, Image, LoadingOverlay, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { AuthApi, GeneralApi } from "../../apis";
import background from "../../assets/background-blur.svg";
import { Form } from "../../components/form";
import { PasswordField } from "../../components/form/password-field";
import { TextField } from "../../components/form/text-field";
import Layout from "../../components/home/layout";
import { authChange, authMe } from "../../store/Auth";
import { initGeneral } from "../../store/General";
import { Message } from "../../utils/message";

const schema = yup.object({
  username: yup.string().required("Кодоо оруулна уу").trim(),
  password: yup.string().required("Нууц үгээ оруулна уу"),
});

type IForm = {
  username: undefined | string;
  password: undefined | string;
};

export default function Login() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data] = React.useState<IForm>({
    username: "",
    password: "",
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      const res = await AuthApi.login(values);
      dispatch(authChange(res));

      const auth = await AuthApi.me();
      const resInit = await GeneralApi.init();

      dispatch(authMe(auth));
      dispatch(initGeneral(resInit));

      navigate("/");
      Message.success("Амжилттай нэвтэрлээ");
      setLoading(false);
    } catch (error: any) {
      Message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Image className={classes.background} src={background} />
      <div className={classes.container}>
        <Grid justify="space-between">
          <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
            <div className={classes.row}>
              <div>
                <Text fz={"lg"} c={"white"}>
                  Сайн байна уу!
                </Text>
                <Text className={classes.text}>Test</Text>
                <div className={classes.description}>-------------newtreh hesegin ug bn-------</div>
              </div>
            </div>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 6 }}>
            <Paper withBorder className={classes.card}>
              <LoadingOverlay visible={loading} />
              <Text fz={40} c={"white"} w={800}>
                НЭВТРЭХ
              </Text>

              <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
                {() => {
                  return (
                    <Grid mt={20} gutter={20}>
                      <Grid.Col h={100} span={12}>
                        <Text fw={400} pb={5} color="white">
                          Хэрэглэгчийн нэрээ оруулна уу:
                        </Text>
                        <TextField variant="unstyled" className={classes.input} size="md" name="username" placeholder="ba***@mail.com..." />
                      </Grid.Col>
                      <Grid.Col h={100} span={12}>
                        <Text fw={400} pb={5} c="white">
                          Нууц үг оруулах:
                        </Text>
                        <PasswordField variant="unstyled" className={classes.passwordInput} size="md" name="password" placeholder="Нууц үг..." />
                      </Grid.Col>

                      <Grid.Col mt={20} span={12}>
                        <Group p="center">
                          <Text c="white">
                            Нууц үг ээ мартсан бол
                            <Link to={"/auth/forgot"}>
                              <Text span c="blue" inherit fw={700} px={5}>
                                ЭНД ДАРЖ
                              </Text>
                            </Link>
                            сэргээнэ үү.
                          </Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={12}>
                        <Button variant="gradient" gradient={{ from: "blue", to: "purple" }} type="submit" size="lg" w="100%">
                          НЭВТРЭХ
                        </Button>
                      </Grid.Col>

                      <Grid.Col mt={100} span={12}>
                        <Group p="center">
                          <Text c="white">Баталгаажуулалт хийх заавартай танилцах</Text>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  );
                }}
              </Form>
            </Paper>
          </Grid.Col>
        </Grid>
      </div>
    </Layout>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    paddingTop: 80,
    paddingBottom: 80,
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 30,
  },
  background: {
    position: "absolute",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  backgroundLine: {
    position: "absolute",
    opacity: 0.1,
    bottom: 0,
    zIndex: -1,
  },
  description: {
    marginTop: 8,
    maxWidth: "1000px",
    fontSize: 14,
    color: "white",
  },

  text: {
    marginTop: -5,
    fontSize: 64,
    fontWeight: 800,
    color: "white",
  },

  card: {
    backgroundColor: "#ffffff25",
    cursor: "pointer",
    borderRadius: "0.5rem",
    padding: "2rem 2.5rem",
    border: 0,
    maxWidth: 500,
    margin: "0 auto",
    [`@media screen and (max-width: 500px)`]: {
      maxWidth: "100%",
    },
  },

  input: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
  },
  passwordInput: {
    input: {
      borderRadius: 4,
      backgroundColor: "#E8F0Fe",
      paddingInline: 10,
    },
    borderRadius: 4,
    backgroundColor: "#E8F0Fe",
    height: 42,
  },
}));
