import { Badge, Button, CloseButton, Divider, Flex, Grid, Group, LoadingOverlay, Text, Title } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { categoryApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";
import { ColumnType, Table } from "../table";

const schema = yup.object({
  name: yup.string().max(100, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  name: string;
  description: string;
  isPublic: boolean;
};

export function SubCategoryFormEdit({
  onCancel,
  action,
  reload,
  title,
  subTitle,
}: {
  onCancel?: () => void;
  action: any[];
  reload: () => void;
  title: string;
  subTitle: string;
}) {
  const [loading, setLoading] = React.useState(false);
  const columns = useHeader();
  const [data] = React.useState<IFormData>({
    itemTypeId: "",
    classificationId: "",
    subClassificationId: "",
    categoryId: "",
    name: "",
    description: "",
    isPublic: false,
    ...(action && action[0] === "update"
      ? {
          ...action[1],
          itemTypeId: action[1].itemType.name,
          classificationId: action[1].classification.name,
          subClassificationId: action[1].subClassification.name,
          categoryId: action[1].category.name,
          name: action[1].name,
          description: action[1].description,
          isPublic: action[1].isPublic,
        }
      : {}),
  });

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      await categoryApi.suUpdate(action && action[1].id, {
        name: values.name,
        description: values.description,
        isPublic: values.isPublic,
      });
      Message.success("Таны хүсэлт амжилттай!");
      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout title={title} subTitle={subTitle} my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
      <LoadingOverlay visible={loading} />
      <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
        {({ values }) => {
          return (
            <Grid>
              <Grid.Col span={4}>
                <TextField name="itemTypeId" label="Нэр төрөл" placeholder="Нэр төрөл" disabled />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="classificationId" label="Ангилал" placeholder="Ангилал" disabled />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="subClassificationId" label="Дэд ангилал" placeholder="Дэд ангилал" disabled />
              </Grid.Col>

              <Grid.Col span={4}>
                <TextField name="categoryId" label="Категори" placeholder="Категори" disabled />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField name="name" label="Дэд категори нэр" placeholder="Дэд категори нэр" required />
              </Grid.Col>
              <Grid.Col span={4}>
                <CheckboxField name="isPublic" label="Нээлттэй" />
              </Grid.Col>

              <Grid.Col span={12}>
                <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>

              <Grid.Col span={12}>
                <Flex direction="row" justify="space-between" align="center">
                  <Title size="md" c="dark.3">
                    Динамик талбарууд
                  </Title>
                </Flex>
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Table name="sub-category-edit.list" columns={columns} dataSource={action[1]?.fields} pagination={false} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <Group>
                  <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                    Болих
                  </Button>
                  <Button key={2} type="submit">
                    Хадгалах
                  </Button>
                </Group>
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>
    </FormLayout>
  );
}

const useHeader = (): ColumnType<any>[] => [
  {
    title: "#",
    width: "30px",
    render: (_r, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {`${index + 1}`}
        </Text>
      );
    },
  },
  {
    title: "Динамик талбарын нэр",
    dataIndex: "name",
    width: 240,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Өгөгдлийн төрөл",
    dataIndex: "type",
    width: 180,
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.type || "-"}
        </Text>
      );
    },
  },
  {
    title: "Сонголтод утга",
    dataIndex: "values",
    render: (record) => {
      return (
        <>
          {record.type === "SELECT"
            ? record?.values?.map((item: any, index: any) => (
                <Badge key={index} variant="dot" mr="xs" color={`${item.isDefault}` === `${true}` ? "green" : "yellow"}>
                  {item.name || "-"}
                </Badge>
              ))
            : "-"}
        </>
      );
    },
  },
  {
    title: "[Default] утга",
    dataIndex: "defaultValue",
    width: 200,
    render: (record, index) => {
      return (
        <>
          {record.type === "SELECT"
            ? record?.values
                ?.filter((c: any) => c.isDefault === true)
                ?.map((value: any, index: number) => (
                  <Badge key={index} variant="dot" mr="xs" color="green">
                    {value.name || "-"}
                  </Badge>
                ))
            : "-"}
        </>
      );
    },
  },
  {
    title: "Нэгж",
    dataIndex: "status",
    width: 120,
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.numberUnit || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эрэмбэ",
    dataIndex: "sort",
    width: 120,
    render: (record, index) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.sort || "-"}
        </Text>
      );
    },
  },
];
