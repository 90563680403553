import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { Button, CloseButton, Divider, Flex, LoadingOverlay } from "@mantine/core";
import { TextField } from "../form/text-field";
import { HttpHandler } from "../../utils/http-handler";
import { manufacturerApi } from "../../apis";
import { Message } from "../../utils/message";
import { CheckboxField } from "../form/checkbox-field";

const schema = yup.object({
  name: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  name: string;
  isPublic: boolean;
};

export function ManufacturerForm({ onCancel, action, reload }: { onCancel?: () => void; action: any; reload: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    name: "",
    isPublic: false,
    ...(action && action[0] === "update" ? action[1] : {}),
  });

  const onSubmit = async (values: IFormData) => {
    const data = {
      name: values.name,
      isPublic: values.isPublic,
    };
    try {
      setLoading(true);

      if (action && action[0] === "update") {
        await manufacturerApi.update(action[1].id, {
          ...data,
        });
      } else {
        await manufacturerApi.create(data);
      }

      Message.success("Таны хүсэлт амжилттай!");
      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout
            title={action && action[0] === "update" ? "Үйлдвэрлэгч засварлах" : "Үйлдвэрлэгч нэмэх"}
            subTitle="Үйлдвэрлэгч мэдээлэл "
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />

            <Flex gap={15} direction="column">
              <Divider />
              <TextField name="name" label="Нэр" placeholder="Нэр" required />
              <CheckboxField name="isPublic" label="Нээлттэй" />
              <Divider mb={10} />
            </Flex>
            <Flex justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
