import { Group, Radio } from "@mantine/core";
import { useField } from ".";
import { IReference } from "../../models/General";

type Props = {
  name: string;
  label?: string;
  disabled?: boolean;
  options: IReference[];
  customOnChange?: (e: any) => void;
  checked?: boolean;
  onClick?: (e: any) => void;
  required?: boolean;
  description?: string;
};

export function CheckboxRadioField({ name, label, disabled = false, options = [], customOnChange, checked, onClick, required, description }: Props) {
  const { error, value, onChange } = useField(name);

  return (
    <Radio.Group
      onChange={(e) => {
        onChange(e);
        customOnChange && customOnChange(e);
      }}
      error={error}
      defaultValue={value}
      value={value}
      name={name}
      label={label}
      description={description}
      withAsterisk={required}>
      <Group pb="6px">
        {options.map((item: IReference, index: number) => {
          return <Radio error={!!error} disabled={disabled} key={index} value={item.code} label={item.name} />;
        })}
      </Group>
    </Radio.Group>
  );
}
