import { MantineThemeOverride } from "@mantine/core";
import { Global } from "@mantine/emotion";

export const GlobalStyle = () => {
  return (
    <Global
      styles={() => ({
        a: {
          color: "inherit",
          textDecoration: "inherit",
        },
      })}
    />
  );
};

export const themeConfig: MantineThemeOverride = {
  colors: {
    indigo: ["#FFF5F5", "#FFE3E3", "#FFC9C9", "#FFA8A8", "#fa7d7d", "#fa6060", "#BB1F46", "#aa1d40", "#E03131", "#c72a2a"],
    indigo2: ["#eef6fc", "#bde0ff", "#8bc1fb", "#73aefa", "#1f70db", "#004c8b", "#144e9d", "#05357e", "#0a2c5f", "#031a3d"],
    indigo3: ["#FFF0F6", "#FFDEEB", "#FCC2D7", "#FAA2C1", "#F783AC", "#F06595", "#E64980", "#D6336C", "#C2255C", "#A61E4D"],
  },
  primaryColor: "indigo",
};
