import { Card, SimpleGrid, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { NavLink } from "react-router-dom";
import { navigationData } from "../constants/navigation";

function ActionsGrid(): JSX.Element {
  const { classes, cx } = useStyles();

  const items = navigationData.map((item) => (
    <NavLink to={item.link} key={item.link}>
      {({ isActive }) => (
        <div key={item.title} className={cx(classes.button, isActive ? classes.active : "")}>
          <div className={classes.icon}>
            <item.icon />
          </div>
          <Text size="xs" mt={2} lh={1.1} style={{ textAlign: "center" }}>
            {item.title}
          </Text>
        </div>
      )}
    </NavLink>
  ));

  return (
    <Card withBorder bg={"transparent"} className={classes.card}>
      <SimpleGrid cols={3}>{items}</SimpleGrid>
    </Card>
  );
}

export { ActionsGrid };

const useStyles = createStyles((theme: any) => {
  return {
    card: {
      border: 0,
    },
    title: {
      fontWeight: 700,
    },
    button: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "120px",
      height: "120px",
      padding: "1rem",
      border: "1px solid #e5e5e5",
      borderRadius: 10,
    },
    active: {
      boxShadow: `${theme.shadows.md}`,
      borderColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : "#bb1f46",
      background: "#ffffff19",
    },
    icon: {
      color: "#bb1f46",
    },
  };
});
