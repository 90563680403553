import { IBankAccountFormData } from "../components/bank-account/form";
import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const create = async (data: IBankAccountFormData) => {
  return httpRequest.post("/pay/back_office/qpay/bank_account", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/pay/back_office/qpay/bank_account", { offset, filter });
};
