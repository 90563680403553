import { ActionIcon, Alert, Button, Divider, Flex, Grid, Group, Input, LoadingOverlay, Text } from "@mantine/core";
import { IconInfoCircle, IconX } from "@tabler/icons-react";
import React from "react";
import useSWR from "swr";
import * as yup from "yup";
import { sectorApi } from "../../apis";
import { IReference } from "../../models/General";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  basic: yup.string().required("Заавал бөглөнө!").nullable(),
  name: yup.string().max(100, "Хэр хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  basic: string;
  parentId: string;
  bogdCode: string;
  golomtCode: string;
  name: string;
  isPublic: boolean;
};

export function SectorForm({ reload, onCancel }: { reload: () => void; onCancel: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IFormData>({
    basic: "",
    parentId: "",
    bogdCode: "",
    golomtCode: "",
    name: "",
    isPublic: false,
  });

  const { data: basicSector } = useSWR(`/prt/back_office/reference/business_sector/select`, async () => {
    try {
      let res = await sectorApi.select({ query: "", parentId: "", limit: "" });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const onSubmit = async (value: IFormData) => {
    setLoading(true);
    try {
      await sectorApi.create({
        name: value.name,
        isPublic: value.isPublic,
        parentId: value.basic,
      });

      onCancel && onCancel();
      reload();
      Message.success("Амжилттай үндсэн ангилал нэмлээ!");
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  return (
    <FormLayout
      my={0}
      title="Сектор нэмэх"
      subTitle="Үндсэн ангилал дотроос задрах эдийн засгийн сектор"
      extra={[
        <ActionIcon onClick={() => onCancel()} key={1}>
          <IconX />
        </ActionIcon>,
      ]}>
      <LoadingOverlay visible={loading} />
      <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
        {({ values }) => {
          return (
            <Grid>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>
              <Grid.Col span={12}>
                <SelectField
                  label="Үндсэн ангилал"
                  name="basic"
                  options={(basicSector || []).map((c: IReference) => ({ label: c.name, value: c.id }))}
                  placeholder="сонгох"
                  searchable
                  required
                />
                {(basicSector || []).length === 0 && (
                  <Alert variant="outline" color="yellow" title="Санамж" icon={<IconInfoCircle />} mt={10}>
                    <Text size="sm" fw={400} c="#44566C">
                      Dehub бизнес платформийн эдийн засгийн үндсэн ангилал нэмнэ үү!
                    </Text>
                  </Alert>
                )}
              </Grid.Col>
              <Grid.Col span={12}>
                <TextField name="name" placeholder="Нэр" label="Нэр" required />
              </Grid.Col>
              <Grid.Col span={12}>
                <Input.Wrapper label="Нээлттэй">
                  <Group>
                    <CheckboxField name="isPublic" label="" />
                    <Text size="sm" fw={500} c="#44566C" mt={11}>
                      {values.isPublic ? "Тийм" : "Үгүй"}
                    </Text>
                  </Group>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={12}>
                <Divider />
              </Grid.Col>

              <Grid.Col span={12}>
                <Flex justify="space-between">
                  <Button color="gray" variant="outline" onClick={() => onCancel && onCancel()}>
                    Буцах
                  </Button>
                  <Button loading={loading} type="submit">
                    Хадгалах
                  </Button>
                </Flex>
              </Grid.Col>
            </Grid>
          );
        }}
      </Form>
    </FormLayout>
  );
}
