import { Badge, MantineColor } from "@mantine/core";

interface ITag {
  color: MantineColor;
  name: string;
}

function Tag(props: ITag): JSX.Element {
  const { color, name } = props;

  if (color.slice(0, 1) === "#")
    return (
      <Badge style={{ border: `${color} 1px solid`, color: color }} variant="outline" size="md" radius="sm">
        {name}
      </Badge>
    );
  else
    return (
      <Badge color={color} variant="outline" size="md" radius="sm">
        {name}
      </Badge>
    );
}

export { Tag };
