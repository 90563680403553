import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/api/user/role", { offset });
};

export const create = async (data: any) => {
  return httpRequest.post(`/api/user/role`, data);
};

export const update = async (id: string, data: any) => {
  return httpRequest.put(`/api/user/role/${id}`, data);
};

export const get = async (id: string) => {
  return httpRequest.get(`/api/user/role/${id}`);
};
