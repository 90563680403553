import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

type IPassword = {
  password: string;
  oldPassword?: string;
};

export const change = async (data: IPassword) => {
  const res = await httpRequest.post(`/api/password/change`, data);
  return res;
};
