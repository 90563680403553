import { Box, Button, CloseButton, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import useSWR from "swr";
import * as yup from "yup";
import { bankAccountApi, merchantApi } from "../../apis";
import { IBusiness } from "../../interfaces/IBusiness";
import { IGeneral } from "../../interfaces/IGeneral";
import { IBank } from "../../models/General";
import { numberRegex } from "../../utils";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object({
  businessId: yup.string().required("Заавал бөглөнө!").nullable(),
  bankName: yup.string().required("Заавал бөглөнө!").nullable(),
  number: yup
    .string()
    .matches(numberRegex, "Тоо оруулах!")
    .max(16, "Хэтэрхий урт байна!")
    .min(8, "Хэтэрхий богино байна!")
    .required("Заавал бөглөнө!")
    .nullable(),
  accountName: yup.string().required("Заавал бөглөнө!").nullable(),
  shortName: yup.string().required("Заавал бөглөнө!").nullable(),
});

export type IBankAccountFormData = {
  partnerId: string;
  businessId: string;
  bankName: string;
  number: string;
  accountName: string;
  shortName: string;
  branchCode: string;
  isDefault: boolean;

  currency?: string;
  qpayMerchantId?: string;
  partnerName?: string;
  regNumber?: string;
};

export function PartnerManagementBankAccountForm({
  onSuccess,
  reload,
  onCancel,
  account,
}: {
  onSuccess: () => void;
  reload: () => void;
  onCancel: () => void;
  account: any;
}) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { bankNames } = useSelector((state: { general: IGeneral }) => state.general);
  const [data] = React.useState<IBankAccountFormData>({
    partnerId: "",
    businessId: "",
    bankName: "",
    number: "",
    shortName: "",
    branchCode: "",
    isDefault: false,
    currency: "Төгрөг",
    qpayMerchantId: "",
    partnerName: "",
    regNumber: "",
    ...(account && account[0] === "ADD"
      ? {
          ...account[1],
          qpayMerchantId: account[1]?.qpayMerchantId,
          partnerName: account[1]?.businessName,
          regNumber: account[1]?.regNumber,
        }
      : {}),
  });

  const onSubmit = async (values: IBankAccountFormData) => {
    setLoading(true);
    try {
      await bankAccountApi.create({
        partnerId: account[1]?.id,
        businessId: values.businessId,
        bankName: values.bankName,
        number: values.number,
        accountName: values.accountName,
        shortName: values.shortName,
        branchCode: values.branchCode,
        isDefault: values.isDefault,
      });
      Message.success("Таны хүсэлт амжилттай данс нэмлээ");
      onSuccess();
      reload();
      onCancel();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const { data: businessData } = useSWR(`/pay/back_office/qpay/merchant/partner/business_select?${account[1]?.id}`, async () => {
    if (!account[1].id) {
      return [];
    }
    let res = await merchantApi.businessselect({ partnerId: account[1]?.id });
    return res;
  });

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, errors }) => {
        return (
          <FormLayout title="QPay - р орлого авах данс нэмэх" subTitle="" my={0} extra={[<CloseButton key="cancel" onClick={() => onCancel()} />]}>
            <Divider />
            <Box>
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="QPAY БҮРТГЭЛИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text c="gray" style={{ fontSize: 14, fontWeight: 500 }}>
                    Харилцагчийн хүсэлтэд ирсэн мэдээллээр үнэн зөв бүргэнэ үү!
                  </Text>
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="qpayMerchantId" label="Мерчант код:" placeholder="нэр" disabled />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="partnerName" label="Нэр:" placeholder="нэр" disabled />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="regNumber" label="Регистр:" placeholder="регистр" disabled />
                </Grid.Col>

                <Grid.Col span={4}>
                  <SelectField
                    name="businessId"
                    label="Бизнес сонгох"
                    placeholder="сонгох"
                    options={(businessData || []).map((c: IBusiness) => ({
                      label: c.profileName,
                      value: c.id,
                    }))}
                    searchable
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бизнесийн код:">
                    <Input value={(businessData || []).find((c: any) => c?.id === values?.businessId)?.refCode || "-"} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бизнесийн нэр:">
                    <Input value={(businessData || []).find((c: any) => c?.id === values?.businessId)?.profileName || "-"} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="ОРЛОГО АВАХ ДАНСНЫ МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <SelectField
                    name="bankName"
                    label="Банкны нэр"
                    placeholder="сонгох"
                    options={(bankNames || []).map((c: IBank) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    searchable
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="number" label="Дансны дугаар:" placeholder="данс" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="accountName" label="Дансны нэр:" placeholder="нэр" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextareaField name="shortName" label="Дансны товч тайлбар:" placeholder="Тайлбар мэдээлэл" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="currency" label="Дансны валют:" placeholder="нэр" disabled />
                </Grid.Col>
                <Grid.Col span={4}>
                  {/* <TextField name="branchCode" label="Салбар:" placeholder="код" /> */}
                  <CheckboxField name="isDefault" label="Үндсэн эсэх" />
                </Grid.Col>
              </Grid>
              <Divider my={15} />
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => onCancel()}>
                  Буцах
                </Button>
                <Button loading={loading} type="submit">
                  Хадгалах
                </Button>
              </Flex>
            </Box>
          </FormLayout>
        );
      }}
    </Form>
  );
}
