import { ActionIcon, AppShell, Avatar, Box, Flex, Group, Menu, Popover, Text, UnstyledButton } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconChevronDown, IconGridDots, IconLogout, IconNotebook, IconPhoto } from "@tabler/icons-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../../apis";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import noimage from "../../assets/no-image.png";
import { NavigationItem } from "../../constants/navigation";
import useUser from "../../hooks/user";
import { authChange, authMe } from "../../store/Auth";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ActionsGrid } from "../popnav";

export const Header = ({ section, toggled }: { section?: NavigationItem; toggled?: boolean }) => {
  const navigate = useNavigate();
  const { user } = useUser();
  const dispatch = useDispatch();

  const { classes, theme } = useStyles();

  const LogOut = async () => {
    try {
      await AuthApi.logout();

      const removeToken = { accessToken: null };
      const removeUser = {};
      dispatch(authChange(removeToken as any));
      dispatch(authMe(removeUser as any));
      navigate("/auth/login");
    } catch (error: any) {
      Message.error((error as HttpHandler).message!);
    }
  };

  return (
    <AppShell.Header>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "100%", width: "100%" }}>
        <Flex align="center" gap={8}>
          <a href="/" className={classes.logoBox}>
            <Logo className={classes.logo} />
            <span>
              <div className={classes.logoText}>DeHUB</div>
              <div className={classes.logoDesc}>платформ</div>
            </span>
          </a>
          <Box h="32px" style={{ borderRight: "1px solid #868E96" }} />
          <Box style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Popover
              zIndex={120}
              radius={theme.radius.sm}
              shadow="lg"
              classNames={{
                dropdown: classes.popover,
              }}>
              <Popover.Target>
                <ActionIcon variant="transparent" color="">
                  <IconGridDots size={86} stroke="2" />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown>
                <ActionsGrid />
              </Popover.Dropdown>
            </Popover>
            <Text className={classes.moduleText}>{section ? <span className={classes.headText}>{section.title}</span> : null}</Text>
          </Box>
        </Flex>
        <Group>
          <Menu width={260} position="bottom-end">
            <Menu.Target>
              <UnstyledButton className={classes.user}>
                <Group>
                  <Avatar src={user?.avatar !== null ? user?.avatar : noimage} radius="xl">
                    <IconPhoto />
                  </Avatar>

                  <div style={{ flex: 1 }}>
                    <Text size="sm" fw={500}>
                      {user?.firstName} {user?.lastName}
                    </Text>
                    <Text c="dimmed" size="xs">
                      {user?.email}
                    </Text>
                  </div>

                  <IconChevronDown size={14} stroke={1.5} />
                </Group>
              </UnstyledButton>
            </Menu.Target>

            <Menu.Dropdown className={classes.dropDown}>
              <Menu.Item onClick={() => navigate("/settings/profile")} leftSection={<IconNotebook size={20} color={theme.colors.indigo[6]} stroke={1.5} />}>
                Миний мэдээлэл
              </Menu.Item>
              <Menu.Item color="red" onClick={() => LogOut()} leftSection={<IconLogout size={20} stroke={1.5} />}>
                Системээс гарах
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </div>
    </AppShell.Header>
  );
};

const useStyles = createStyles((theme) => ({
  headerWrap: {
    background: "#bb1f46",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    justifyItems: "center",
    backgroundColor: "#e5e7eb",
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0 20px",
  },
  moduleText: {
    color: "#bb1f46",
    fontSize: "1.1rem",
    fontWeight: 600,
  },
  logo: {
    height: 32,
  },
  logoText: {
    fontSize: 20,
    fontWeight: 600,
    color: "#bb1f46",
    lineHeight: "18px",
  },
  logoDesc: {
    fontSize: 10,
    fontWeight: 500,
    color: theme.colors.gray[6],
    textAlign: "end",
  },
  user: {
    color: theme.black,
    height: 60,
    padding: theme.spacing.xs,
    "&:hover": {
      backgroundColor: "#00000040",
    },
  },
  dropDown: {
    marginTop: 5,
  },
  popover: {
    marginTop: 15,
    border: `1px solid ${theme.colors.indigo[1]}`,
    background: "#fff",
    padding: 0,
  },
  headText: {
    color: "#bb1f46",
    fontSize: "1.1rem",
    fontWeight: 600,
  },
}));
