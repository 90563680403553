import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { FormLayout } from "../layout";
import { Box, Button, CloseButton, Divider, Flex, Grid, Input, Text } from "@mantine/core";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import useSWR from "swr";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { merchantApi } from "../../apis";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { IAuth } from "../../interfaces/IAuth";
import { dateSecFormat } from "../../utils/date";
import { TextareaField } from "../form/textarea-field";
import { registerNumber, mn } from "../../utils";

const schema = yup.object({
  partnerId: yup.string().required("Заавал бөглөнө!").nullable(),
  mccCode: yup.string().required("Заавал бөглөнө!").nullable(),
  province: yup.string().required("Заавал бөглөнө!").nullable(),
  district: yup.string().required("Заавал бөглөнө!").nullable(),

  registerNo: yup.string().matches(registerNumber, "Регистр дугаараа дахин шалгана уу!").trim().required("Заавал бөглөнө!"),
  lastName: yup.string().max(100, "Хэтэрхий урт байна!").matches(mn, "Нэрийг кирилл бичин үү!").required("Заавал бөглөнө!").nullable(),
  firstName: yup.string().max(100, "Хэтэрхий урт байна!").matches(mn, "Нэрийг кирилл бичин үү!").required("Заавал бөглөнө!").nullable(),

  phone: yup
    .string()
    .matches(/^[0-9]{8}$/, "Дугаарын формат буруу байна")
    .required("Заавал бөглөнө!"),
  email: yup.string().typeError("Заавал бөглөнө!").email("Цахим шуудан байна!").required("Заавал бөглөнө!"),

  locationLat: yup.string().required("Заавал бөглөнө!").nullable(),
  locationLng: yup.string().required("Заавал бөглөнө!").nullable(),
  address: yup.string().max(255, "Хэтэрхий урт байна!").max(255, "Хэтэрхий урт байна!").required("Заавал бөглөнө!").nullable(),
});

export type IMerchantFormData = {
  partnerId: string;
  registerNo: string;
  firstName: string;
  lastName: string;
  locationLat: string;
  locationLng: string;
  mccCode: string;
  province: string;
  district: string;
  address: string;
  phone: string;
  email: string;
};

export function MerchantForm({ action, onSuccess, reload }: { action: any; onSuccess: () => void; reload: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const nowMoment = new Date();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const { qpayMccCodes, zipCodes } = useSelector((state: { general: IGeneral }) => state.general);
  const [partner, setPartner] = React.useState<string>("");
  const [data] = React.useState<IMerchantFormData>({
    partnerId: "",
    registerNo: "",
    firstName: "",
    lastName: "",
    mccCode: "",
    province: "",
    district: "",
    address: "",
    phone: "",
    email: "",
    locationLat: "",
    locationLng: "",
  });

  const { data: merchantData } = useSWR(`/pay/back_office/qpay/merchant/select/data`, async () => {
    try {
      let res = await merchantApi.select({
        query: "",
        limit: 50,
      });
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  const onSubmit = async (values: IMerchantFormData) => {
    setLoading(true);
    try {
      await merchantApi.create(values);
      Message.success("Таны хүсэлт амжилттай");
      onSuccess();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const { data: partnerData } = useSWR(`/pay/back_office/qpay/merchant/partner/get?${partner}`, async () => {
    try {
      if (!partner) {
        return [];
      }
      let res = await merchantApi.get(partner);
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <FormLayout title="QPay мерчант бүртгэл нэмэх" subTitle="" my={0} extra={[<CloseButton key="cancel" onClick={() => onSuccess()} />]}>
            <Divider />
            <Box>
              <Grid>
                <Grid.Col span={4}>
                  <SelectField
                    name="partnerId"
                    label="Хайлт хийх"
                    placeholder="хайх"
                    options={(merchantData || []).map((c: any) => ({
                      label: c.businessName,
                      value: c.id,
                    }))}
                    onChange={(e) => {
                      setPartner(e as string);
                    }}
                    searchable
                    required
                  />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="QPAY БҮРТГЭЛИЙН МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Text c="gray" style={{ label: { fontSize: 14, fontWeight: 500 } }}>
                    Харилцагчийн хүсэлтэд ирсэн мэдээллээр үнэн зөв бүргэнэ үү!
                  </Text>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Мерчант код:">
                    <Input value={(partnerData || []).length === 0 ? "-" : `${partnerData[0]?.qpayMerchantId || "-"}`} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүртгэсэн ажилтан:">
                    <Input value={`${user?.lastName[0] || "-"}. ${user?.firstName || "-"}`} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Бүртгэсэн огноо, цаг:">
                    <Input value={dateSecFormat(nowMoment)} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Партнер код:">
                    <Input value={(partnerData || []).length === 0 ? "-" : `${partnerData[0]?.refCode || "-"}`} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Нэр:">
                    <Input value={(partnerData || []).length === 0 ? "-" : `${partnerData[0]?.businessName || "-"}`} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Регистр:">
                    <Input value={(partnerData || []).length === 0 ? "-" : `${partnerData[0]?.regNumber || "-"}`} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="registerNo" placeholder="Эзний регистр №" label="Ажилтны регистр №" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="lastName" placeholder="Нэр" label="Эзний овог" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="firstName" placeholder="Эзний нэр" label="Нэр" required />
                </Grid.Col>

                <Grid.Col span={4}>
                  <SelectField
                    name="mccCode"
                    label="Сектор сонгох"
                    placeholder="сонгох"
                    options={(qpayMccCodes || []).map((c: IReference) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    searchable
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="MCC код:">
                    <Input value={(qpayMccCodes || []).find((c: any) => c?.code === values?.mccCode)?.code || "-"} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={4}>
                  <Input.Wrapper label="Сектор нэр:">
                    <Input value={(qpayMccCodes || []).find((c: any) => c?.code === values?.mccCode)?.name || "-"} onChange={() => {}} disabled />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} w="100%" label="ХАЯГЫН МЭДЭЭЛЭЛ" />
                </Grid.Col>

                <Grid.Col span={4}>
                  <SelectField
                    options={(zipCodes || [])?.filter((code: IReference) => code?.parent === "0")?.map((c: any) => ({ label: c?.name, value: c?.code }))}
                    label="Аймаг, нийслэл"
                    name="province"
                    placeholder="Аймаг, нийслэл"
                    onChange={() => {
                      setFieldValue("district", undefined);
                    }}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <SelectField
                    options={(zipCodes || [])
                      ?.filter((code: IReference) => code?.parent === values?.province)
                      ?.map((c: any) => ({ label: c?.name, value: c?.code }))}
                    label="Сум, дүүрэг"
                    name="district"
                    placeholder="Сум, дүүрэг"
                    disabled={!values.province}
                    required
                  />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextareaField name="address" label="Хаяг бичгээр" placeholder="Тайлбар мэдээлэл" required />
                </Grid.Col>

                <Grid.Col span={4}>
                  <TextField name="email" placeholder="Имэйл хаяг" label="Имэйл хаяг" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField name="phone" placeholder="Утасны дугаар" label="Утасны дугаар" required />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>

                <Grid.Col span={4}>
                  <TextField label="Өргөрөг" name="locationLat" placeholder="Өргөрөг" required />
                </Grid.Col>
                <Grid.Col span={4}>
                  <TextField label="Уртраг" name="locationLng" placeholder="Уртраг" required />
                </Grid.Col>
                <Grid.Col span={4}></Grid.Col>
              </Grid>
              <Divider my={15} />
              <Flex direction="row" justify="space-between">
                <Button variant="default" onClick={() => onSuccess()}>
                  Буцах
                </Button>
                <Button loading={loading} type="submit">
                  Хадгалах
                </Button>
              </Flex>
            </Box>
          </FormLayout>
        );
      }}
    </Form>
  );
}
