import { showNotification } from "@mantine/notifications";

const success = (message: string, description?: string, title?: string) => {
  showNotification({
    color: "green",
    title: title || "Хүсэлт амжилттай!",
    message,
  });
};

const error = (message: string) => {
  showNotification({
    color: "red",
    title: "Хүсэлт амжилтгүй!",
    message,
  });
};

export const Message = {
  success,
  error,
};
