import { HttpRequest } from "../utils";
import { IFilter } from "../interfaces/IFilter";

const httpRequest = new HttpRequest();

export const create = async (data: { name: string; description: string; isGoods: boolean; isService: boolean }) => {
  return httpRequest.post("/erp/back_office/item_reference/item_type", data);
};

export const select = async (data: { isPublic?: boolean; isDehub?: boolean; query: ""; limit: "" }) => {
  return httpRequest.get("/erp/back_office/item_reference/item_type/select", data);
};

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/erp/back_office/item_reference/item_type", { offset, filter });
};

export const update = async (id: string, data: { name: string; description: string; isGoods: boolean; isService: boolean }) => {
  return httpRequest.put(`/erp/back_office/item_reference/item_type/${id}`, data);
};

export const remove = async (id: string) => {
  return httpRequest.del(`/erp/back_office/item_reference/item_type/${id}`);
};
