import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const upload = async (data: any) => {
  return httpRequest.upload("/api/general/upload", data);
};

export const init = async () => {
  return httpRequest.get("/prt/general/init");
};
