import { Button, Divider, Flex, LoadingOverlay } from "@mantine/core";
import React from "react";
import * as yup from "yup";
import { PasswordApi } from "../../apis";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { PasswordField } from "../form/password-field";

const schema = yup.object({
  oldPassword: yup.string().required("Хуучин нууц үг заавал бөглөх"),
  password: yup.string().min(6, "Багадаа 6 тэмдэгттэй байх ёстой").max(16, "Ихдээ 16 тэмдэгттэй байх ёстой").required("Нууц үг оруулана уу"),
  checkPassword: yup
    .string()
    .min(6, "Багадаа 6 тэмдэгттэй байх ёстой")
    .max(16, "Ихдээ 16 тэмдэгттэй байх ёстой")
    .required("Нууц үг оруулана уу")
    .oneOf([yup.ref("password")], "Нууц үг таарахгүй байна"),
});

type IForm = {
  oldPassword: undefined | string;
  password: undefined | string;
  checkPassword: undefined | string;
};

export function Changepassword({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data] = React.useState<IForm>({
    oldPassword: undefined,
    password: undefined,
    checkPassword: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      const res = await PasswordApi.change({ password: values?.password as string, oldPassword: values?.oldPassword as string });
      Message.success(res?.message || "Нууц үг амжилттай солигдлоо");
      onClose();
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      Message.error(error?.message);
    }
  };

  return (
    <Form initialValues={data} onSubmit={onSubmit} validationSchema={schema}>
      {() => {
        return (
          <Flex direction="column" gap={"md"}>
            <LoadingOverlay visible={loading} />
            <PasswordField name="oldPassword" label="Хуучин нууц үг" placeholder="Хуучин нууц үг" />
            <PasswordField name="password" label="Шинэ нууц үг" placeholder="Шинэ нууц үг" />
            <PasswordField name="checkPassword" label="Давтан нууц үг" placeholder="Давтан нууц үг" />
            <Divider />

            <Flex justify="space-between" align="center">
              <Button variant="outline" color="gray">
                Болих
              </Button>
              <Button type="submit" variant="filled">
                Хадгалах
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
