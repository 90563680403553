import { ActionIcon, Box, Button, CloseButton, Divider, Flex, Grid, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBrandFacebook, IconBrandInstagram, IconBrandLinkedin, IconBrandTwitter, IconPhoneOutgoing } from "@tabler/icons-react";
import React from "react";
import useSwr from "swr";
import * as yup from "yup";
import { PartnerApi } from "../../apis";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { FormLayout } from "../layout";

const schema = yup.object({
  trxConfirmDate: yup.string().required("Заавал бөглөнө үү!").nullable(),
});

type Props = {
  action: any;
  setAction: any;
  reload: any;
};

type IFormData = {};

export function PartnerManagementAdminInviteForm({ action, setAction, reload }: Props) {
  const { classes } = useStyle();
  const [data] = React.useState<IFormData>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const { data: partnerData } = useSwr(
    `partner-data.${action && action[1]?.partnerId}`,
    async () => {
      if (action && action[1]?.partnerId === undefined) {
        return [];
      }

      let res = await PartnerApi.get(action && action[1]?.partnerId);
      return res;
    },
    {},
  );

  const onSubmit = async () => {
    setLoading(true);
    try {
      await PartnerApi.send(action && action[1]?.id);

      if (action[1]?.registerStatus === "DRAFT") {
        Message.success("Урилга амжилттай илгээгдлээ.");
      } else {
        Message.success("Дахин урилга амжилттай илгээгдлээ.");
      }
      reload();
      setAction([]);
    } catch (err: any) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const title = (val: string) => {
    switch (val) {
      case "DRAFT": {
        return "Бүртгэл баталгаажуулах урилга илгээх";
      }
      case "INVITED": {
        return "Бүртгэл баталгаажуулах урилга дахин илгээх";
      }
    }
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout
            title={`${title(action[1]?.registerStatus as string)}`}
            subTitle=""
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => setAction([])} />]}>
            <Divider color="gray" />
            <Box>
              <Flex mih={50} justify="center" align="center" direction="column" wrap="wrap">
                <Text c="dimmed" className={classes.title} mb="md">
                  Та итгэлтэй байна уу?
                </Text>
                <Text c="dimmed" className={classes.text}>
                  Та <span className={classes.height}>{(action && action[1]?.lastName) || "-"}</span> овогтой{" "}
                  <span className={classes.height}>{(action && action[1]?.firstName) || "-"}</span> - ийг{" "}
                  <span className={classes.height}>{partnerData?.businessName || "-"}</span> - ийг төлөөлөх платформын админ болгох гэж байна.
                </Text>
                <Text c="dimmed" className={classes.text}>
                  Доорх мэдээллийг шалгаад "Зөв байна. Илгээе" сонголт хийнэ үү.
                </Text>
              </Flex>
              <Box>
                <Grid justify="center" gutter="xs">
                  <Grid.Col span={6} mt={10} mb={10}>
                    <Grid.Col span={12}>
                      <Text className={classes.firstText}>Байгууллагын нэр:</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.firstText}>Партнер код:</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.firstText}>Админы регистр:</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.firstText}>Админы овог нэр:</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.firstText}>Админы и-мэйл:</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.firstText}>Админы утас:</Text>
                    </Grid.Col>
                  </Grid.Col>
                  <Grid.Col span={6} mt={10} mb={10}>
                    <Grid.Col span={12}>
                      <Text className={classes.lastText}>{partnerData?.businessName || "-"}</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.lastText}>{partnerData?.refCode || "-"}</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.lastText}>{partnerData?.admin?.registerNo || "-"}</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.lastText}>
                        {action && action[1]?.lastName ? action && action[1]?.lastName[0] : ""}
                        {action && action[1]?.lastName && ". "}
                        {action && action[1]?.firstName ? action && action[1]?.firstName : "-"}
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.lastText}>{partnerData?.admin?.email || "-"}</Text>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text className={classes.lastText}>{partnerData?.admin?.phone || "-"}</Text>
                    </Grid.Col>
                  </Grid.Col>
                </Grid>
              </Box>

              <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
                <Button variant="outline" onClick={() => setAction([])}>
                  Буцах
                </Button>
                <Button variant="filled" type="submit" loading={loading}>
                  Илгээх
                </Button>
              </Flex>
              <Flex mih={50} gap="md" justify="center" align="center" direction="column" wrap="wrap">
                <Text c="dimmed" className={classes.text}>
                  Танд амжилт хүсье.
                </Text>
              </Flex>
              <Grid>
                <Grid.Col span={12}>
                  <Divider mr="20%" ml="20%" mb={10} mt={10} />
                </Grid.Col>
              </Grid>
              <Flex mih={50} gap="md" justify="center" align="center" direction="column" wrap="wrap">
                <Text c="dimmed" className={classes.text}>
                  Дижитал Эра Дэймэнт ХХК
                </Text>
              </Flex>
              <Flex mih={50} gap="md" justify="center" align="center" direction="row" wrap="wrap">
                <ActionIcon mr={15}>
                  <IconBrandInstagram />
                </ActionIcon>
                <ActionIcon mr={15}>
                  <IconBrandFacebook />
                </ActionIcon>
                <ActionIcon mr={15}>
                  <IconPhoneOutgoing />
                </ActionIcon>
                <ActionIcon mr={15}>
                  <IconBrandLinkedin />
                </ActionIcon>
                <ActionIcon>
                  <IconBrandTwitter />
                </ActionIcon>
              </Flex>
            </Box>
          </FormLayout>
        );
      }}
    </Form>
  );
}

const useStyle = createStyles(() => ({
  text: {
    fontSize: "20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
  },
  height: {
    fontWeight: 700,
  },
  firstText: {
    fontSize: "18px",
    color: "black",
    fontWeight: 600,
    textAlign: "right",
  },
  lastText: {
    fontSize: "18px",
    color: "#3B5BDB",
    fontWeight: 600,
  },
}));

// Төлбөрийн тохиргоо
//  - Нэхэмжлэх
//  - Бэлэн
//  - Авлага
