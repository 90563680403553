import { Paper } from "@mantine/core";
import { useParams } from "react-router";
import { PageLayout } from "../../../components/layout";
import { AdjustmentNoteList } from "../../../components/product-reference/adjustment-note-list";
import { ReferenceBrandList } from "../../../components/product-reference/brand-list";
import { CategoryList } from "../../../components/product-reference/category-list";
import { ClassificationList } from "../../../components/product-reference/classification-list";
import { DeliveryList } from "../../../components/product-reference/delivery-list";
import { DistributorList } from "../../../components/product-reference/distributor-list";
import { InActiveTypeList } from "../../../components/product-reference/in-active-type-list";
import { ItemTypeList } from "../../../components/product-reference/item-type-list";
import { ManufacturerList } from "../../../components/product-reference/manufacturer-list";
import { OptionList } from "../../../components/product-reference/option-list";
import { PackageList } from "../../../components/product-reference/package-list";
import { SectionList } from "../../../components/product-reference/section-list";
import { SubCategoryList } from "../../../components/product-reference/sub-category-list";
import { SubClassificationList } from "../../../components/product-reference/sub-classification-list";
import { TagList } from "../../../components/product-reference/tag-list";
import { UnitList } from "../../../components/product-reference/unit-list";

export function ReferenceProductType() {
  const params = useParams();

  const titles = (val: string) => {
    switch (val) {
      case "BRAND": {
        return "Брэнд";
      }
      case "SECTION": {
        // saijruulah
        return "Барааны нэмэлт мэдээлэл";
      }
      case "TAG": {
        return "Таг";
      }
      case "DISTRIBUTOR": {
        return "Дистрибютр";
      }
      case "MANUFACTURER": {
        return "Үйлдвэрлэгч";
      }
      case "UNIT": {
        return "Нэгж";
      }
      case "PACKAGE_TYPE": {
        return "Сав, баглаа, боодол";
      }
      case "DELIVERY_TYPE": {
        return "Хүргэлтийн нөхцөл";
      }
      case "ITEM_TYPE": {
        return "Нэр төрөл";
      }
      case "CLASSIFICATION": {
        return "Ангилал";
      }
      case "SUB_CLASSIFICATION": {
        return "Дэд ангилал";
      }
      case "CATEGORY": {
        return "Категори";
      }
      case "SUB_CATEGORY": {
        return "Дэд категорийн";
      }

      case "OPTION": {
        return "Бараа бүтээгдэхүүн, ажил үйлчилгээний хувилбар";
      }
      case "INACTIVE_TYPE": {
        return "Бараа бүтээгдэхүүн идэвхгүй болгох шалтгааны жагсаалт";
      }
      case "ADJUSTMENT_NOTE": {
        return "Нөөцийн хөдөлгөөний тайлбар";
      }
    }
  };

  const breadcrumbs = useBreadcrumb(titles(params.type as string));

  const itemType = (val: string) => {
    switch (val) {
      case "BRAND": {
        return <ReferenceBrandList />;
      }
      case "TAG": {
        return <TagList />;
      }
      case "DISTRIBUTOR": {
        return <DistributorList />;
      }
      case "MANUFACTURER": {
        return <ManufacturerList />;
      }
      case "UNIT": {
        return <UnitList />;
      }
      case "DELIVERY_TYPE": {
        return <DeliveryList />;
      }
      case "ITEM_TYPE": {
        return <ItemTypeList />;
      }
      case "CLASSIFICATION": {
        return <ClassificationList />;
      }
      case "SUB_CLASSIFICATION": {
        return <SubClassificationList />;
      }
      case "CATEGORY": {
        return <CategoryList />;
      }
      case "SUB_CATEGORY": {
        return <SubCategoryList />;
      }
      case "PACKAGE_TYPE": {
        return <PackageList />;
      }
      case "SECTION": {
        return <SectionList />;
      }
      case "OPTION": {
        return <OptionList />;
      }
      case "INACTIVE_TYPE": {
        return <InActiveTypeList />;
      }
      case "ADJUSTMENT_NOTE": {
        return <AdjustmentNoteList />;
      }
    }
  };

  return (
    <PageLayout title="" breadcrumb={breadcrumbs} bgColor="">
      <Paper radius="sm" p="md" withBorder>
        {itemType(params.type as string)}
      </Paper>
    </PageLayout>
  );
}

const useBreadcrumb = (type: any) => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/reference/product-list",
    label: "Барааны лавлах мэдээлэл",
  },
  { label: type },
];
