import { Avatar, Box, Checkbox, Divider, Flex, Grid, Group, Input, Text } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { IconPhoto } from "@tabler/icons-react";
import React from "react";
import { bankAccountApi } from "../../apis";
import { IBankAccount } from "../../interfaces/IBankAccount";
import { ColumnType, Table } from "../table";

export function PartnerManagementBankAccountList() {
  const [regNumber, setRegNumber] = React.useState("");
  const [debouncedRegNumber] = useDebouncedValue(regNumber, 600);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "detail": {
          break;
        }
      }
    },
  });

  return (
    <Flex direction={"column"} gap="md">
      <Box w={"100%"}>
        <Grid mb="xs">
          <Grid.Col span={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <Input
              value={regNumber}
              leftSectionWidth={80}
              placeholder="хайх"
              style={{ borderRight: "none", borderRadius: "4px 0 0 4px" }}
              leftSection={
                <Group w={"calc(100% - 20px)"} align="center">
                  <Text size="sm" fw={450} c="#44566C">
                    {"ТТД"}
                  </Text>
                  <Text>:</Text>
                </Group>
              }
              onChange={(e) => {
                setRegNumber(e?.currentTarget?.value);
              }}
              minLength={7}
              maxLength={7}
            />
          </Grid.Col>
        </Grid>
        <Divider color="gray" mt={10} mb={10} />
        <Table
          name="partner-management-bank-account.list"
          columns={columns}
          filters={{ regNumber: debouncedRegNumber, businessId: "", partnerId: "" }}
          loadData={(data) => bankAccountApi.list(data!)}
        />
      </Box>
    </Flex>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: IBankAccount) => void }): ColumnType<IBankAccount>[] => [
  //   {
  //     title: "Үйлдэл",
  //     align: "right",
  //     sorter: true,
  //     dataIndex: "sku",
  //     render: (record) => {
  //       return (
  //         <Flex gap="sm">
  //           <Tooltip label="Дэлгэрэнгүй" position="bottom">
  //             <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("detail", record)}>
  //               <IconInfoSquare />
  //             </Button>
  //           </Tooltip>
  //         </Flex>
  //       );
  //     },
  //   },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Зураг",
    dataIndex: "icon",
    render: (record) => {
      return (
        <Avatar src={`${record.icon}`} alt="" size={40}>
          <IconPhoto />
        </Avatar>
      );
    },
  },
  {
    title: "Мерчант ID",
    sorter: true,
    dataIndex: "qpayMerchantId",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.qpayMerchantId || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр",
    sorter: true,
    dataIndex: "regNumber",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.business?.regNumber || "-"}
        </Text>
      );
    },
  },
  {
    title: "Партнер нэр",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Group>
          <div style={{ width: "200px" }}>
            <Text size="sm" fw={500} c="#141517">
              {record?.business?.partner?.businessName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" fw={500} c="#44566C">
                {record?.business?.partner?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Бизнес нэр",
    sorter: true,
    dataIndex: "profileName",
    width: 150,
    render: (record) => {
      return (
        <Group>
          <div style={{ width: "200px" }}>
            <Text size="sm" fw={500} c="#141517">
              {record?.business?.profileName || "-"}
            </Text>
            <Flex gap={5} wrap="nowrap">
              <Text size="sm" fw={500} c="#44566C">
                {record?.business?.refCode || "-"}
              </Text>
            </Flex>
          </div>
        </Group>
      );
    },
  },
  {
    title: "Банкны нэр",
    sorter: true,
    dataIndex: "bankName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.bankName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Дансны дугаар",
    sorter: true,
    dataIndex: "number",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.number || "-"}
        </Text>
      );
    },
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "businessName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {"Төгрөг"}
        </Text>
      );
    },
  },
  {
    title: "Үндсэн эсэх",
    sorter: true,
    dataIndex: "shortName",
    render: (record) => (
      <Flex gap={8} align="center">
        <Checkbox checked={record.isDefault} onChange={() => {}} /> {record.isDefault ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
  {
    title: "Товч нэр",
    sorter: true,
    dataIndex: "shortName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.shortName || "-"}
        </Text>
      );
    },
  },
];
