import { IFilter } from "../interfaces/IFilter";
import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

export const list = async ({ offset, filter }: IFilter) => {
  return httpRequest.get("/biz/back_office/payment_term", { offset, filter });
};

export const create = async (data: any) => {
  return httpRequest.post(`/biz/back_office/payment_term`, data);
};
