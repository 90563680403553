import { Badge, Button, Divider, Group, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { sectionApi } from "../../apis";
import { ISection } from "../../interfaces/ISection";
import { Dialog } from "../../utils/confirm-modal";
import { dateTimeFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { SectionForm } from "./section-form";

type ISectionData = {
  id: string;
  name: string;
  isPublic: boolean;
  isDehub: boolean;
  createdAt: string;
};

export function SectionList() {
  const navigate = useNavigate();
  const [filters] = React.useState({ query: "" });
  const [action, setAction] = React.useState<string[]>([]);
  const [payload, setPayload] = React.useState<ISectionData | null>();
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);

  const columns = useHeader({
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setPayload(item);
          setAction(["update", item as any]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await sectionApi.remove(item.id);
                  ref.current?.reload();
                  Message.success("Амжилттай брэндийг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  Message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
    setPayload(null);
  };

  const reload = () => {
    ref.current?.reload();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Барааны нэмэлт мэдээлэл"
        subTitle="Барааны нэмэлт мэдээлэлийн жагсаалт, шинээр үүсгэх, засварлах"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
          <Button
            key={2}
            onClick={() => {
              setAction(["new"]);
            }}>
            Нэмэх
          </Button>,
        ]}>
        <Divider my={10} />
        <Table ref={ref} name="section.list" columns={columns} filters={filters} loadData={(data) => sectionApi.list(data!)} />
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} size="80%" centered>
        <SectionForm onCancel={onCancel} action={action} payload={payload || undefined} reload={reload} />
      </Modal>
    </>
  );
}

const useHeader = ({ onClick }: { onClick: (key: string, record: ISection) => void }): ColumnType<ISection>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Нэр",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нээлттэй эсэх",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green" : "red"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "DeHub эсэх",
    dataIndex: "isDehub",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isDehub ? "green" : "red"}>
          {record.isDehub ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateTimeFormat(record.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Group>
          <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
            <IconEdit />
          </Button>
          <Button variant="light" color="red" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
            <IconTrash />
          </Button>
        </Group>
      );
    },
  },
];
