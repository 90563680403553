import React from "react";
import * as yup from "yup";
import { Form } from "../form";
import { Button, Divider, Flex, Grid, Text } from "@mantine/core";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { useSelector } from "react-redux";
import { IGeneral } from "../../interfaces/IGeneral";
import { useNavigate, useParams } from "react-router";
import { IReference } from "../../models/General";

const schema = yup.object({
  province: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  district: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  khoroo: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  khotkhonBair: yup.string().required("Заавал бөглөнө!"),
  khoroolol: yup.string().required("Заавал бөглөнө!"),
  khashaaDavkhar: yup.string().required("Заавал бөглөнө!"),
  khaalgaDugaar: yup.string().required("Заавал бөглөнө!"),
  businessAddress: yup.string().required("Заавал бөглөнө!"),
  locationLat: yup.number().typeError("Тоогоор оруулна уу").required("Заавал бөглөнө!"),
  locationLng: yup.number().min(0, "asdasd").typeError("Тоогоор оруулна уу").required("Заавал бөглөнө!"),
});

type IFormData = {
  province: string | undefined;
  district: string | undefined;
  khoroo: string | undefined;
  khotkhonBair: string | undefined;
  khoroolol: string | undefined;
  khashaaDavkhar: string | undefined;
  khaalgaDugaar: string | undefined;
  businessAddress: string | undefined;
  locationLat: number | undefined;
  locationLng: number | undefined;
};

type Props = {
  action: any[];
  onSubmit: (values: IFormData, type: boolean) => void;
};

export function PartnerManagementPartnerAddressForm({ onSubmit: _onSubmit, action }: Props) {
  const navigate = useNavigate();
  const params = useParams();
  const { zipCodes } = useSelector((state: { general: IGeneral }) => state.general);
  const [type, setType] = React.useState(false);
  const [data] = React.useState<IFormData>({
    province: "",
    district: "",
    khoroo: "",
    khotkhonBair: "",
    khoroolol: "",
    khashaaDavkhar: "",
    khaalgaDugaar: "",
    businessAddress: "",
    locationLat: "",
    locationLng: "",
    ...((action && action[0] === "update") || (action && action[0] === "detail") ? action[1] : {}),
  });

  const onSubmit = (values: IFormData) => {
    _onSubmit(values, type);
  };

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {({ values, setFieldValue }) => {
        return (
          <Flex style={{ position: "relative" }} direction="column" justify="space-between" gap="md">
            <Grid gutter="sm">
              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ХАЯГИЙН МЭДЭЭЛЭЛ" />
              </Grid.Col>

              <Grid.Col span={4}>
                <SelectField
                  options={(zipCodes || [])?.filter((code: IReference) => code?.parent === "0")?.map((c: any) => ({ label: c?.name, value: c?.code }))}
                  label="Аймаг, нийслэл"
                  name="province"
                  placeholder="Аймаг, нийслэл"
                  disabled={action && action[0] === "detail"}
                  onChange={() => {
                    setFieldValue("district", undefined);
                    setFieldValue("khoroo", undefined);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  options={(zipCodes || [])
                    ?.filter((code: IReference) => code?.parent === values?.province)
                    ?.map((c: any) => ({ label: c?.name, value: c?.code }))}
                  label="Сум, дүүрэг"
                  name="district"
                  placeholder="Сум, дүүрэг"
                  disabled={(action && action[0] === "detail") || !values?.province}
                  onChange={() => {
                    setFieldValue("khoroo", undefined);
                  }}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <SelectField
                  options={(zipCodes || [])
                    ?.filter((code: IReference) => code?.parent === values?.district)
                    ?.map((c: IReference) => ({ label: c?.name, value: c?.code }))}
                  label="Баг, хороо"
                  name="khoroo"
                  placeholder="Баг, хороо"
                  disabled={(action && action[0] === "detail") || !values?.province || !values?.district}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField label="Хороолол, гудамж" name="khoroolol" placeholder="Хороолол, гудамж" required disabled={action && action[0] === "detail"} />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField label="Хотхон, байр" name="khotkhonBair" placeholder="Хотхон, байр" required disabled={action && action[0] === "detail"} />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField label="Хашаа, давхар" name="khashaaDavkhar" placeholder="Хашаа, давхар" required disabled={action && action[0] === "detail"} />
              </Grid.Col>
              <Grid.Col span={4}>
                <TextField label="Хаалганы дугаар" name="khaalgaDugaar" placeholder="Хаалганы дугаар" required disabled={action && action[0] === "detail"} />
              </Grid.Col>
              <Grid.Col span={8}>
                <TextareaField
                  label="Хаягын бичгэн тайлбар"
                  name="businessAddress"
                  placeholder="Хаягын бичгэн тайлбар"
                  required
                  disabled={action && action[0] === "detail"}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ГАЗРЫН ЗУРАГ ДЭЭРХ БАЙРШИЛ" />
              </Grid.Col>

              {/* <Grid.Col span={8}>
                <Box h={600} w="100%">
                  <GoogleMap
                    center={{
                      lat: Number(values?.locationLat || 47.9179864),
                      lng: Number(values?.locationLng || 106.9175798),
                    }}
                  />
                </Box>
              </Grid.Col> */}
              <Grid.Col span={4}>
                <Grid>
                  <Grid.Col span={12}>
                    <Text fz={14}>Газрын зураг дээр сонгож тэмдэглэвэл автоматаар уртраг, өргөрөг хадгалагдана.</Text>
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextField label="Өргөрөг" name="locationLat" placeholder="Өргөрөг" required disabled={action && action[0] === "detail"} />
                  </Grid.Col>
                  <Grid.Col span={12}>
                    <TextField label="Уртраг" name="locationLng" placeholder="Уртраг" required disabled={action && action[0] === "detail"} />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>
            <Divider />
            <Flex direction="row" justify="space-between">
              <Button variant="default" onClick={() => navigate(`/partner-management/partner/${params.type}/edit/${params.id}/1`)}>
                Буцах
              </Button>
              <Flex gap="md">
                {/* <Button
                  variant="outline"
                  onClick={(e) => {
                    setType(false);
                    onSubmit(data);
                  }}
                  disabled={action && action[0] === "detail"}>
                  Хадгалах
                </Button> */}
                <Button type="submit" onClick={() => setType(true)} disabled={action && action[0] === "detail"}>
                  Үргэжлүүлэх
                </Button>
              </Flex>
            </Flex>
          </Flex>
        );
      }}
    </Form>
  );
}
