import { Button, CloseButton, Divider, Flex, LoadingOverlay } from "@mantine/core";
import React from "react";
import useSWR from "swr";
import * as yup from "yup";
import { categoryApi, itemTypeApi } from "../../apis";
import { IItemType } from "../../interfaces/IItemType";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { FormLayout } from "../layout";

const schema = yup.object().shape({
  itemTypeId: yup.string().required("Заавал бөглөнө!").nullable(),
  name: yup.string().required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  itemTypeId: string;
  name: string;
  description: string;
  isPublic: boolean;
};

export function ClassificationForm({ onCancel, action, reload }: { onCancel?: () => void; action: any; reload: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const [data] = React.useState<IFormData>({
    itemTypeId: "",
    name: "",
    description: "",
    isPublic: false,
    ...(action && action[0] === "update" ? { ...action[1], itemTypeId: action[1]?.itemType?.id } : {}),
  });

  const onSubmit = async (values: IFormData) => {
    const dataC = {
      name: values.name,
      itemTypeId: values.itemTypeId,
      description: values.description,
      type: "CLASSIFICATION",
      isPublic: values.isPublic,
    };

    const dataE = {
      name: values.name,
      description: values.description,
      isPublic: values.isPublic,
    };

    setLoading(true);
    try {
      if (action && action[0] === "update") {
        await categoryApi.update(action[1].id, {
          ...dataE,
        });
      } else {
        await categoryApi.create(dataC);
      }

      Message.success("Таны хүсэлт амжилттай!");
      onCancel && onCancel();
      reload();
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const { data: ItemData } = useSWR(
    `/erp/back_office/item_reference/item_type/select/classfication`,
    async () => {
      let res = await itemTypeApi.select({ query: "", limit: "" });
      return res;
    },
    {},
  );

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={data}>
      {() => {
        return (
          <FormLayout
            title={action && action[0] === "update" ? "Ангилал засварлах" : "Ангилал нэмэх"}
            subTitle="Бараа бүтээгдэхүүн, ажил үйлчилгээний ангиллын мэдээлэл"
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              <Divider />
              <SelectField
                name="itemTypeId"
                label="Нэр төрөл"
                placeholder="Нэр төрөл"
                options={(ItemData || []).map((c: IItemType) => ({ label: c.name, value: c.id }))}
                disabled={action && action[0] === "update"}
                required
              />
              <TextField name="name" label="Нэр" placeholder="Нэр" required />
              <CheckboxField name="isPublic" label="Нээлттэй" />
              <TextareaField name="description" label="Тайлбар" placeholder="Тайлбар" required />
              <Divider mb={10} />
            </Flex>
            <Flex justify="space-between">
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Flex>
          </FormLayout>
        );
      }}
    </Form>
  );
}
