import { Box, Divider, Grid, LoadingOverlay, Tabs, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBuilding, IconMap, IconUser } from "@tabler/icons-react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { PartnerApi } from "../../../apis";
import { PageLayout } from "../../../components/layout";
import { PartnerManagementPartnerAddressForm } from "../../../components/partner/address-form";
import { PartnerManagementAdminList } from "../../../components/partner/admin-list";
import { PartnerManagementPartnerMainForm } from "../../../components/partner/main-form";
import HttpHandler from "../../../utils/http-handler";
import { Message } from "../../../utils/message";

export function PartnerManagementPartnerDetail() {
  const params = useParams();
  const { id, step } = params;
  const { classes } = useStyles();
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(step || "1");
  const [loading, setLoading] = React.useState(false);

  const { data, mutate } = useSwr<any>(`partner-management-partner.detail.${params.id}`, async () => await PartnerApi.get(id as string), {
    fallback: { data: null },
  });

  const onMainSubmit = async (data: any, type: boolean) => {
    setLoading(true);
    try {
      const res = await PartnerApi.updateInfo(`${params?.id}`, {
        type: params?.type,
        partnerCategory: data?.partnerCategory,
        classification: data?.classification,
        regNumber: data?.regNumber.toString(),
        businessName: data?.businessName,
        businessNameEng: data?.businessNameEng,
        legalEntityType: data?.legalEntityType,
        country: data?.country,
        equityType: data?.equityType,
        web: data?.web,
        email: data?.email,
        email2: data?.email2,
        phone: data?.phone,
        phone2: data?.phone2,
        fbLink: data?.fbLink,
        isContinue: type,
      });
      if (type) {
        navigate(`/partner-management/partner/${params.type}/edit/${res.id}/2`);
        await mutate();
        onTabChange("2");
        Message.success("Партнерийг амжилттай засварлан хадгаллаа.");
      } else {
        navigate("/partner-management/partner");
        Message.success("Партнерийг амжилттай хадгаллаа.");
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onAddressSubmit = async (data: any, type: boolean) => {
    setLoading(true);
    const res = await PartnerApi.address(`${params?.id}`, {
      province: data?.province || "",
      district: data?.district || "",
      khoroo: data?.khoroo || "",
      khotkhonBair: data?.khotkhonBair || "",
      khoroolol: data?.khoroolol || "",
      khashaaDavkhar: data?.khashaaDavkhar || "",
      khaalgaDugaar: data?.khaalgaDugaar || "",
      businessAddress: data?.businessAddress || "",
      locationLat: data?.locationLat || 47.9179864,
      locationLng: data?.locationLng || 106.9175798,
      isContinue: type,
    });
    try {
      if (type) {
        navigate(`/partner-management/partner/${params.type}/edit/${res.id}/3`);
        await mutate();
        onTabChange("3");
        Message.success("Хаягийн мэдээллийг амжилттай засварлан хадгаллаа.");
      } else {
        navigate("/partner-management/partner");
        Message.success("Хаягийн мэдээллийг амжилттай хадгаллаа.");
      }
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
    setLoading(false);
  };

  const onTabChange = (index: string | null) => {
    navigate(`/partner-management/partner/${params.type}/detail/${id}/${index}`);
    if (index !== null) setTabIndex(index);
  };

  return (
    <PageLayout title="Партнерийн мэдээлэл" subTitle="Партнерийн мэдээллийн дэлгэрэнгүй" breadcrumb={breadcrumbs}>
      <Divider mb="lg" />
      <Grid gutter="md">
        <Grid.Col span={{ base: 12 }}>
          <Box px={20} py={16} className={classes.gridBox}>
            <Tabs variant="pills" defaultValue="1" value={tabIndex} onChange={onTabChange}>
              <LoadingOverlay visible={!data || loading} />
              <Tabs.List>
                <Tabs.Tab leftSection={<IconBuilding color={tabIndex === "1" ? "white" : "gray"} />} value="1">
                  <Text c={tabIndex === "1" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
                </Tabs.Tab>
                <Tabs.Tab leftSection={<IconMap color={tabIndex === "2" ? "white" : "gray"} />} value="2">
                  <Text c={tabIndex === "2" ? "" : "gray"}>Хаягийн мэдээлэл</Text>
                </Tabs.Tab>
                <Tabs.Tab leftSection={<IconUser color={tabIndex === "3" ? "white" : "gray"} />} value="3">
                  <Text c={tabIndex === "3" ? "" : "gray"}>Админ хэрэглэгч</Text>
                </Tabs.Tab>
              </Tabs.List>
              <Divider mt="md" mb="md" />
              <Tabs.Panel value="1">
                {tabIndex === "1" && data && <PartnerManagementPartnerMainForm onSubmit={onMainSubmit} action={["detail", data]} />}
              </Tabs.Panel>
              <Tabs.Panel value="2">
                {tabIndex === "2" && data && <PartnerManagementPartnerAddressForm onSubmit={onAddressSubmit} action={["detail", data]} />}
              </Tabs.Panel>
              <Tabs.Panel value="3">
                <PartnerManagementAdminList actionType={["detail", data]} />
              </Tabs.Panel>
            </Tabs>
          </Box>
        </Grid.Col>
      </Grid>
    </PageLayout>
  );
}

const useStyles = createStyles((theme) => ({
  gridBox: {
    height: "100%",
    border: `1px solid ${theme.colors.gray[2]}`,
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
  },
}));

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    to: "/partner-management/partner",
    label: "Партнер жагсаалт",
  },
  {
    label: "Партнерийн мэдээллийн дэлгэрэнгүй",
  },
];
