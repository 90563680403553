import {
  IconAdjustments,
  IconAlignBoxBottomCenter,
  IconClipboard,
  IconClipboardList,
  IconCoins,
  IconCreditCard,
  IconFileText,
  IconListDetails,
  IconNote,
  IconQrcode,
  IconRepeat,
  IconReplace,
  IconReport,
  IconSettings,
  IconSettings2,
  IconSettingsQuestion,
  IconUsers,
  IconWallet,
} from "@tabler/icons-react";

export interface NavigationItem {
  title: string;
  description?: string;
  icon: React.FC;
  color: string;
  link: string;
  disabled?: boolean;
  children?: NavigationItem[];
  parent?: NavigationItem;
  homeLink?: string;
}

const navigationData: NavigationItem[] = [
  {
    description: "Партнерын бүртгэл, хяналт, гэрээний харилцаа болон супер админы эрх идэвхжүүлэх",
    title: "Партнер удирдлага",
    icon: IconAdjustments,
    color: "indigo",
    link: "/partner-management",
    homeLink: "partner",
    children: [
      {
        title: "Партнер жагсаалт",
        icon: IconListDetails,
        color: "indigo",
        link: "/partner-management/partner",
      },
      {
        title: "CP-Admin жагсаалт",
        icon: IconCoins,
        color: "indigo",
        link: "/partner-management/admin",
      },
      {
        title: "Хэрэгчдийн жагсаалт",
        icon: IconUsers,
        color: "indigo",
        link: "/partner-management/user",
      },
      {
        title: "Секторын лавлах",
        icon: IconAlignBoxBottomCenter,
        color: "indigo",
        link: "/partner-management/sector",
      },
      {
        title: "QPay мерчант",
        icon: IconQrcode,
        color: "indigo",
        link: "/partner-management/merchant",
      },
    ],
  },
  {
    description: "Хувийн мэдээллийн дэлгэрэнгүй",
    title: "Миний мэдээлэл тохиргоо",
    icon: IconSettings,
    color: "cyan",
    link: "/settings",
    disabled: true,
    children: [
      {
        title: "Миний мэдээлэл",
        icon: IconFileText,
        color: "indigo",
        link: "/settings/profile",
      },
    ],
  },
  {
    description: "ДЭП ХХК-ны систем хэрэглэгчийн бүртгэл, эрхийн тохиргоо, лог",
    title: "Систем хэрэглэгч",
    icon: IconUsers,
    color: "red",
    link: "/dcf",
    children: [],
    disabled: true,
  },
  {
    description: "Платформын хэмжээнд ашиглах лавлах мэдээллийн бүртгэл, тохиргоо",
    title: "Лавлах тохиргоо",
    icon: IconSettings2,
    color: "lime",
    link: "/reference",
    homeLink: "b2b-list",
    children: [
      {
        title: "Төлбөрийн нөхцөл",
        icon: IconCreditCard,
        color: "indigo",
        link: "/reference/b2b-list",
      },
      {
        title: "Барааны лавлах",
        icon: IconReport,
        color: "indigo",
        link: "/reference/product-list",
      },
    ],
    disabled: false,
  },
  {
    description: "Хэрэглэгчийн лог бүртгэл, мэйл сервис, SMS сервис болон интеграцитай холбоотой үйлдлийн бүртгэл",
    title: "Системийн лог",
    icon: IconNote,
    color: "orange",
    link: "/payment",
    children: [],
    disabled: true,
  },
  {
    description: "Ажилтнуудад зориулсан заавар, гарын авлага, асуулт хариултууд",
    title: "Асуулт хариулт, заавар",
    icon: IconSettingsQuestion,
    color: "grape",
    link: "/customer",
    disabled: true,
    children: [
      {
        title: "Анкор Корпорэйт",
        icon: IconClipboardList,
        color: "indigo",
        link: "/customer/anchor-corporate",
      },
      {
        title: "SME Onboarding",
        icon: IconClipboard,
        color: "indigo",
        link: "/customer/sme-onboarding",
      },
      {
        title: "Supplier рольтой",
        icon: IconRepeat,
        color: "indigo",
        link: "/customer/supplier-role",
      },
      {
        title: "Buyer рольтой",
        icon: IconReplace,
        color: "indigo",
        link: "/customer/buyer-role",
      },
      {
        title: "B2B Данс тохиргоо",
        icon: IconWallet,
        color: "indigo",
        link: "/customer/b2b-accounting",
      },
    ],
  },
];

const navigationDataMappedByLink = navigationData.reduce((acc, item) => {
  acc[item.link] = item;
  if (item.children) {
    item.children.forEach((child) => {
      acc[child.link] = {
        ...child,
        parent: item,
      };
    });
  }
  return acc;
}, {} as Record<string, NavigationItem>);

const navigationDataMappedByParentLink = navigationData.reduce((acc, item) => {
  if (item.children) {
    acc[item.link] = item.children;
  }
  return acc;
}, {} as Record<string, NavigationItem[]>);

export { navigationData, navigationDataMappedByLink, navigationDataMappedByParentLink };
