import { HttpRequest } from "../utils";

const httpRequest = new HttpRequest();

type IOtp = {
  otpMethod: string;
};

export const get = async (data: IOtp) => {
  const res = await httpRequest.get("/api/otp", data);
  return res;
};

export const verify = async (data: { otpMethod: string; otpCode: string | number }) => {
  const res = await httpRequest.post(`/api/otp/verify`, data);
  return res;
};
