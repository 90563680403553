import { Badge, Button, Divider, Modal, Text } from "@mantine/core";
import React, { useRef } from "react";
import { sectorApi } from "../../apis";
import { ISector } from "../../interfaces/ISector";
import { PageLayout } from "../layout";
import { ColumnType, ITableRef, Table } from "../table";
import { SectorForm } from "./form";

export function SectorList() {
  const ref = useRef<ITableRef>(null);
  const breadcrumb = useBreadcrumb();
  const [action, setAction] = React.useState<string[]>([]);

  const columns = useHeader();

  const onCancel = () => {
    setAction([]);
  };

  const reload = () => {
    ref.current?.reload();
  };

  return (
    <>
      <PageLayout
        title="Сектор жагсаалт"
        subTitle="Үндсэн ангилал дотроос задрах эдийн засгийн сектор"
        breadcrumb={breadcrumb}
        extra={[
          <Button onClick={() => setAction(["SECTOR"])} key={1}>
            Нэмэх
          </Button>,
        ]}>
        <Divider mb={5} />
        <Table
          ref={ref}
          name="partner-management-user-sector-data.list"
          columns={columns}
          filters={{ level: "2", query: "" }}
          loadData={(data) => sectorApi.list(data!)}
        />
      </PageLayout>
      <Modal opened={action[0] === "SECTOR"} onClose={() => onCancel()} withCloseButton={false} size="lg" centered>
        <SectorForm reload={reload} onCancel={onCancel} />
      </Modal>
    </>
  );
}

const useHeader = (): ColumnType<ISector>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Үндсэн ангилал",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.parent?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.name ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Код",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.refCode ?? "-"}
        </Text>
      );
    },
  },
  {
    title: "Нээлттэй",
    sorter: true,
    dataIndex: "refCode",
    render: (record) => {
      return (
        <Badge variant="outline" color={record.isPublic ? "green" : "red"}>
          {record.isPublic ? "Тийм" : "Үгүй"}
        </Badge>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Секторын лавлах жагсаалт",
    to: "/partner-management/sector",
  },
  {
    label: "Секторын жагсаалт",
  },
];
