import { Badge, Button, Flex, SegmentedControl, Text, Tooltip } from "@mantine/core";
import { IconCircleMinus, IconEdit, IconInfoSquareRounded, IconMailForward, IconUserCheck } from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { PartnerAdminApi } from "../../apis";
import { IAdminCompany } from "../../interfaces/IAdminCompany";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { Dialog } from "../../utils/confirm-modal";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ColumnType, ITableRef, Table } from "../table";
import { Tag } from "../tags";

function PartnerManagementAdminCompanyList(): JSX.Element {
  const [filters, setFilters] = React.useState({ partnerType: "COMPANY", userStatus: "ACTIVE" });
  const { departmentUnits, userStatus, userRegisterStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const ref = React.useRef<ITableRef>(null);

  const columns = useHeader({
    userRegisterStatus,
    userStatus,
    departmentUnits,
    onClick: (key, _item) => {
      switch (key) {
        case "edit": {
          break;
        }
        case "details": {
          // navigate(`/dehub/partner/${item.id}`);
          break;
        }
        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                try {
                  // await PartnerApi.remove(`${item?.id}`);
                  reload();
                  Message.success("Ажилтан амжилттай устгагдлаа");
                } catch (err: any) {
                  Message.error((err as HttpHandler)?.message!);
                }
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });

  const reload = async () => {
    ref.current?.reload();
  };

  return (
    <>
      <SegmentedControl
        onChange={(e: string) => {
          setFilters({ ...filters, userStatus: e });
        }}
        value={filters.userStatus}
        data={userStatus?.map((item: IReference) => {
          return {
            label: item.name,
            value: item?.code,
          };
        })}
        radius="md"
        size="md"
        mb="md"
      />
      <Table ref={ref} name="partner-management-admin-company.list" columns={columns} filters={filters} loadData={(data) => PartnerAdminApi.list(data!)} />
    </>
  );
}

export { PartnerManagementAdminCompanyList };

type HeaderProps = {
  onClick: (key: string, record: IAdminCompany) => void;
  departmentUnits: IReference[];
  userStatus: IReference[];
  userRegisterStatus: IReference[];
};

const useHeader = ({ onClick, userRegisterStatus, userStatus }: HeaderProps): ColumnType<IAdminCompany>[] => [
  {
    title: "Үйлдэл",
    render: (record) => {
      return (
        <Flex gap={10}>
          <Tooltip label="Идэвхгүй болгох" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("inactive", record)}>
              <IconCircleMinus />
            </Button>
          </Tooltip>
          <Tooltip label="Засварлах" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
              <IconEdit />
            </Button>
          </Tooltip>
          <Tooltip label="Дэлгэрэнгүй" position="top">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("details", record)}>
              <IconInfoSquareRounded />
            </Button>
          </Tooltip>
          <Tooltip label="Урилга илгээх" position="top">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("send", record)}>
              <IconMailForward />
            </Button>
          </Tooltip>
          <Tooltip label="Эрх тохируулах" position="bottom">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("role", record)}>
              <IconUserCheck />
            </Button>
          </Tooltip>
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хэрэглэгчийн нэр",
    sorter: true,
    dataIndex: "username",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.username || "-"}
        </Text>
      );
    },
  },
  {
    title: "Ургийн овог",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.familyName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эцэг эхийн нэр",
    sorter: true,
    dataIndex: "lastName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.lastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Өөрийн нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр №",
    sorter: true,
    dataIndex: "registerNo",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.registerNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн нэр",
    sorter: true,
    dataIndex: "departmentUnit",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" style={{ width: 150 }}>
          {record?.departmentUnit?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Албан тушаал",
    sorter: true,
    dataIndex: "employeeUnit",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C" style={{ width: 150 }}>
          {record?.employeeUnit?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "И-мэйл хаяг",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас 1",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас 2",
    sorter: true,
    dataIndex: "phone2",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.phone2 || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Хэрэглэгчийн статус",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => (
      <Tag
        color={userStatus.find((item: IReference) => item.code === record.userStatus)?.color || "gray"}
        name={userStatus.find((item: IReference) => item.code === record.userStatus)?.name || "Идэвхгүй"}
      />
    ),
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => (
      <Tag
        color={userRegisterStatus?.find((item: IReference) => item.code === record.registerStatus)?.color || "gray"}
        name={userRegisterStatus?.find((item: IReference) => item.code === record.registerStatus)?.name || "Амжилтгүй"}
      />
    ),
  },
  {
    title: "Утас баталгаажсан эсэх",
    sorter: true,
    dataIndex: "isPhoneVerified",
    render: (record) => (
      <Badge variant="outline" color={record.isPhoneVerified ? "green" : "red"}>
        {record.isPhoneVerified ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Имэйл баталгаажсан эсэх",
    sorter: true,
    dataIndex: "isEmailVerified",
    render: (record) => (
      <Badge variant="outline" color={record.isEmailVerified ? "green" : "red"}>
        {record.isEmailVerified ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Нууц үгтэй эсэх",
    sorter: true,
    dataIndex: "hasPassword",
    render: (record) => (
      <Badge variant="outline" color={record.hasPassword ? "green" : "red"}>
        {record.hasPassword ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "ДАН - р баталгаажсан эсэх",
    sorter: true,
    dataIndex: "isDanVerified",
    render: (record) => (
      <Badge variant="outline" color={record.isDanVerified ? "green" : "red"}>
        {record.isDanVerified ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
];
