import { Button, LoadingOverlay, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { AuthApi, PasswordApi } from "../../apis";
import { authChange, authMe } from "../../store/Auth";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { PasswordField } from "../form/password-field";

type IForm = {
  password: string | undefined;
  checkpassword: string | undefined;
};

const schema = yup.object({
  password: yup.string().min(6, "Багадаа 6 тэмдэгттэй байх ёстой").max(16, "Ихдээ 16 тэмдэгттэй байх ёстой").required("Нууц үг оруулана уу"),
  checkPassword: yup
    .string()
    .min(6, "Багадаа 6 тэмдэгттэй байх ёстой")
    .max(16, "Ихдээ 16 тэмдэгттэй байх ёстой")
    .required("Нууц үг оруулана уу")
    .oneOf([yup.ref("password")], "Нууц үг таарахгүй байна"),
});

export function CreatePassword() {
  const { classes } = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [data] = React.useState<IForm>({
    password: undefined,
    checkpassword: undefined,
  });

  const onSubmit = async (values: IForm) => {
    setLoading(true);
    try {
      const res = await PasswordApi.change({ password: values?.password as any });
      dispatch(authChange(res));
      const auth = await AuthApi.me();
      dispatch(authMe(auth));

      setLoading(false);
      navigate("/");
      Message.success("Баталгаажуулалт амжилттай");
    } catch (error: any) {
      Message.error(error?.message);
      setLoading(false);
    }
  };

  return (
    <div className={classes.card}>
      <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
        {() => {
          return (
            <div className={classes.card}>
              <LoadingOverlay visible={loading} />
              <div className={classes.icon}></div>
              <Text className={classes.title}>Нууц үг үүсгэх</Text>
              <Text ta="center" className={classes.bodyText}>
                Таны үүсгэсэн нууц үг дараа таныг нэвтрэхэд шаардагдах тул өөрийн мэдээлэлийн аюулгүй байдлын төлөө хэнтэй ч хуваалцахгүй байна уу
              </Text>
              <PasswordField radius={4} size="md" className={classes.input} name="password" placeholder="Нууц үг" />
              <PasswordField radius={4} size="md" className={classes.input} name="checkPassword" placeholder="Давтан нууц үг" />
              <Button type="submit" variant="gradient" radius={4} size={"md"} className={classes.button}>
                Баталгаажуулах
              </Button>
            </div>
          );
        }}
      </Form>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    maxWidth: "1600px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    paddingTop: 80,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 80,
    paddingLeft: 30,
    paddingRight: 30,
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  background: {
    position: "absolute",
    height: "100vh",
    opacity: 0.8,
    bottom: 0,
    zIndex: -1,
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 420,
    maxWidth: 624,
    borderRadius: 10,
    gap: 10,
    position: "relative",
  },
  title: {
    fontSize: 26,
    fontWeight: 700,
    color: "black",
  },
  bodyText: {
    width: "80%",
    fontSize: 16,
    fontWeight: 400,
    paddingRight: 15,
    paddingLeft: 15,
    color: "black",
  },
  input: {
    height: 60,
    width: "60%",
  },
  button: {
    marginTop: 15,
  },
  bottomText: {
    marginTop: 25,
    color: "white",
  },
  icon: {},
}));
