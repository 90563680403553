import { createSlice } from "@reduxjs/toolkit";
import { IGeneral } from "../interfaces/IGeneral";

const initialState: IGeneral = {
  businessSectors: [],
  businessTypes: [
    {
      code: "SUPPLIER",
      name: "Нийлүүлэгч",
    },
    {
      code: "BUYER",
      name: "Нийлүүлэгч",
    },
  ],
  fieldTypes: [],
  contractStatus: [],
  departmentUnits: [],
  districts: [],
  employeeUnits: [],
  equityTypes: [],
  khoroos: [],
  legalEntityTypes: [],
  partnerCategories: [],
  partnerClassifications: [],
  partnerRegisterStatus: [],
  partnerTypes: [],
  permissions: [],
  productCategories: [],
  provinces: [],
  socialTypes: [],
  userRegisterStatus: [],
  userStatus: [],
  partnerStatus: [],
  partnerRegistrationType: [],
  bankNames: [],
  branchStatus: [],
  branchTypes: [],
  businessStaffRoles: [],
  businessStatus: [],
  contractDocTypes: [],
  productCategoryTypes: [],
  purchaseTypes: [],
  serviceCategoryTypes: [],
  warehouseStatus: [],
  serviceCategories: [],
  businesses: [],
  partnerStaffs: [],
  countries: [],
  zipCodes: [],
  paymentTermConditions: [],
  paymentTermConfigTypes: [],
  qpayMccCodes: [],
};

const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    initGeneral: (state, { payload }: { payload: IGeneral }) => {
      const {
        fieldTypes,
        businessSectors,
        productCategories,
        userStatus,
        departmentUnits,
        employeeUnits,
        permissions,
        partnerClassifications,
        partnerTypes,
        equityTypes,
        khoroos,
        legalEntityTypes,
        provinces,
        districts,
        partnerCategories,
        socialTypes,
        partnerRegisterStatus,
        contractStatus,
        userRegisterStatus,
        partnerStatus,
        partnerRegistrationType,
        branchStatus,
        branchTypes,
        businessStaffRoles,
        businessStatus,
        contractDocTypes,
        productCategoryTypes,
        purchaseTypes,
        serviceCategoryTypes,
        warehouseStatus,
        serviceCategories,
        businesses,
        partnerStaffs,
        countries,
        zipCodes,
        paymentTermConditions,
        paymentTermConfigTypes,
        qpayMccCodes,
        bankNames,
      } = payload;
      state.businessSectors = businessSectors;
      state.productCategories = productCategories;
      state.userStatus = userStatus;
      state.departmentUnits = departmentUnits;
      state.employeeUnits = employeeUnits;
      state.permissions = permissions;
      state.partnerClassifications = partnerClassifications;
      state.partnerTypes = partnerTypes;
      state.fieldTypes = fieldTypes;
      state.khoroos = khoroos;
      state.khoroos = khoroos;
      state.equityTypes = equityTypes;
      state.legalEntityTypes = legalEntityTypes;
      state.provinces = provinces;
      state.districts = districts;
      state.partnerCategories = partnerCategories;
      state.socialTypes = socialTypes;
      state.partnerRegisterStatus = partnerRegisterStatus;
      state.contractStatus = contractStatus;
      state.userRegisterStatus = userRegisterStatus;
      state.partnerStatus = partnerStatus;
      state.partnerRegistrationType = partnerRegistrationType;
      state.branchStatus = branchStatus;
      state.branchTypes = branchTypes;
      state.businessStaffRoles = businessStaffRoles;
      state.businessStatus = businessStatus;
      state.contractDocTypes = contractDocTypes;
      state.productCategoryTypes = productCategoryTypes;
      state.purchaseTypes = purchaseTypes;
      state.serviceCategoryTypes = serviceCategoryTypes;
      state.warehouseStatus = warehouseStatus;
      state.serviceCategories = serviceCategories;
      state.businesses = businesses;
      state.partnerStaffs = partnerStaffs;
      state.countries = countries;
      state.zipCodes = zipCodes;
      state.paymentTermConditions = paymentTermConditions;
      state.paymentTermConfigTypes = paymentTermConfigTypes;
      state.qpayMccCodes = qpayMccCodes;
      state.bankNames = bankNames;
      return state;
    },
  },
});

export const generalReducer = generalSlice.reducer;

export const { initGeneral } = generalSlice.actions;
