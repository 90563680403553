import { Button, CloseButton, Divider, Flex, Group, LoadingOverlay } from "@mantine/core";
import React from "react";
import useSWR from "swr";
import * as yup from "yup";
import { sectionApi } from "../../apis";
import { IBrand } from "../../interfaces/IBrand";
import { HttpHandler } from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { Form } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { DynamicFieldForm, IDynamicRef } from "./dynamic-field-form";

const schema = yup.object({
  name: yup.string().required("Заавал бөглөнө!").nullable(),
});

type IFormData = {
  id?: string;
  name: string;
  isPublic: boolean;
  fieldIds: string;
};

const SectionForm = React.memo(({ onCancel, action, payload, reload }: { onCancel?: () => void; action: any[]; payload?: IBrand; reload?: any }) => {
  const dynamicRef = React.useRef<IDynamicRef>(null);
  const [loading, setLoading] = React.useState(false);
  const [datas] = React.useState<IFormData>({
    name: "",
    isPublic: false,
    fieldIds: "",

    ...(payload ? payload : {}),
  });

  const onSubmit = async (data: IFormData) => {
    let valid = await dynamicRef.current?.submit();
    if (!valid) {
      return Message.error("Динамик талбарууд алдаатай байна!");
    } else {
      if (valid?.fields?.length > 0) {
        try {
          setLoading(true);

          if (payload) {
            await sectionApi.update(payload.id, {
              name: data.name,
              isPublic: data.isPublic,
            });
            setLoading(false);
          } else {
            await sectionApi.create({
              name: data.name,
              isPublic: data.isPublic,
              sectionFields: (valid?.fields || [])
                .sort((a, b) => a.sort - b.sort)
                .map((field) => ({
                  name: field.name!,
                  type: field.type!,
                  numberUnit: field.numberUnit!,
                  values: (field.values || [])?.map((value: string) => ({
                    name: value,
                    isDefault: true,
                  })),
                })),
            });
            setLoading(false);
          }

          reload();
          onCancel && onCancel();
          Message.success("Жагсаалт амжилттай хадгаллаа");
        } catch (err) {
          setLoading(false);

          Message.error((err as HttpHandler)?.message!);
        }
      } else {
        Message.error("Жагсаалт хоосон байж болохгүй");
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data } = useSWR(`/`, async () => {
    try {
      if (action && action[0] !== "update") {
        return [];
      }
      let res = await sectionApi.get(action && (action[1].id as string));
      return res;
    } catch (err) {
      Message.error((err as HttpHandler)?.message!);
    }
  });

  console.log(" === SECTION === ");
  console.log(action);
  console.log(payload);
  console.log(" === ======= === ");

  return (
    <Form validationSchema={schema} onSubmit={onSubmit} initialValues={datas}>
      {() => {
        return (
          <FormLayout
            title="Барааны нэмэлт мэдээлэл"
            subTitle="Барааны нэмэлт мэдээлэл оруулна уу."
            my={0}
            extra={[<CloseButton key="cancel" onClick={() => onCancel && onCancel()} />]}>
            <LoadingOverlay visible={loading} />
            <Flex gap={15} direction="column">
              <Divider />
              <TextField name="name" label="Нэр" placeholder="Нэр" required />
              <CheckboxField label="Нээлттэй" name="isPublic" />
              <Divider />
              <DynamicFieldForm ref={dynamicRef} />
            </Flex>

            <Group mt={20}>
              <Button key={1} variant="default" onClick={() => onCancel && onCancel()}>
                Болих
              </Button>
              <Button key={2} type="submit">
                Хадгалах
              </Button>
            </Group>
          </FormLayout>
        );
      }}
    </Form>
  );
});

SectionForm.displayName = "SectionFieldForm";

export { SectionForm };
