import { Button, Checkbox, Drawer, Flex, LoadingOverlay, Modal, Paper, Text, Tooltip } from "@mantine/core";
import {
  IconCircleCheck,
  IconCircleMinus,
  IconEdit,
  IconInfoSquareRounded,
  IconMailForward,
  IconMailOpened,
  IconReload,
  IconSend,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { PartnerApi } from "../../apis";
import { IAdmin } from "../../interfaces/IAdmin";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { Dialog } from "../../utils/confirm-modal";
import { dateSecFormat } from "../../utils/date";
import HttpHandler from "../../utils/http-handler";
import { Message } from "../../utils/message";
import { ColumnType, Table } from "../table";
import { Tag } from "../tags";
import { PartnerManagementAdminForm } from "./admin-form";
import { PartnerManagementAdminInviteForm } from "./invite-form";

export function PartnerManagementAdminList({ actionType }: { actionType: any }) {
  const params = useParams();
  const ref = React.useRef<any>(null);
  const [action, setAction] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { userStatus, userRegisterStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const [filters] = React.useState({ partnerId: `${params.id}` });

  const columns = useHeader({
    userStatus,
    loading,
    userRegisterStatus,
    actionType,
    onClick: (key, record) => {
      switch (key) {
        case "edit": {
          setAction(["update", record as any]);
          break;
        }
        case "details": {
          setAction(["detail", record as any]);
          return;
        }
        case "remove": {
          Dialog.confirm("Та устгахдаа илгээхдээ итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await PartnerApi.removeAdmin(record?.id);
                  reload();
                  Message.success("Амжилттай устгалаа.");
                } catch (err: any) {
                  Message.error((err as HttpHandler)?.message!);
                }
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "send": {
          setAction(["INVITE", record as any]);
          break;
        }
        case "otp": {
          Dialog.confirm("Та нэг удаагийн нууц үг дахин илгээхдээ итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await PartnerApi.remove(record?.id);
                  reload();
                  Message.success("Амжилттай илгээгдлээ.");
                } catch (err: any) {
                  Message.error((err as HttpHandler)?.message!);
                }
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "password": {
          Dialog.confirm("Та нууц үг сэргээх мэйл илгээхдээ итгэлтэй байна уу ?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await PartnerApi.remove(record?.id);
                  reload();
                  Message.success("Амжилттай илгээгдлээ.");
                } catch (err: any) {
                  Message.error((err as HttpHandler)?.message!);
                }
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }

        case "active": {
          Dialog.confirm("Та админ хэрэглэгчийг идэвхтэй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await PartnerApi.adminActive(record?.partnerId, record?.id);
                  Message.success("Амжилттай идэвхтэй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
        case "in_active": {
          Dialog.confirm("Та админ хэрэглэгчийг идэвхгүй болгох гэж байна итгэлтэй байна уу!", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  await PartnerApi.adminInActive(record?.partnerId, record?.id);
                  Message.success("Амжилттай идэвхгүй болгосон.");
                } catch (err) {
                  Message.error((err as HttpHandler)?.message!);
                }
                reload();
                setLoading(false);
                break;
              }
              default:
            }
          });
          break;
        }
      }
    },
  });
  const reload = async () => {
    await ref.current?.reload();
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <Paper>
      <Flex align="center" justify="space-between" gap="md" mb="md">
        <Text tt="uppercase" c="gray">
          Админы жагсаалт
        </Text>
        <Button
          onClick={() => {
            setAction(["new"]);
          }}
          disabled={actionType[1]?.registerStatus === "DRAFT"}
          key={2}>
          Админ нэмэх
        </Button>
      </Flex>

      <Table ref={ref} name="partner-management-partner-admin.list" columns={columns} filters={filters} loadData={(data) => PartnerApi.adminList(data!)} />
      <Drawer
        withCloseButton={false}
        title={false}
        position="right"
        padding={"lg"}
        size={"70%"}
        opened={action[0] === "new" || action[0] === "update" || action[0] === "detail"}
        onClose={() => setAction([])}>
        <PartnerManagementAdminForm reload={reload} action={action} setAction={setAction} />
      </Drawer>
      <Modal opened={action[0] === "INVITE"} onClose={() => setAction([])} withCloseButton={false} size="50%" centered>
        <PartnerManagementAdminInviteForm action={action} setAction={setAction} reload={reload} />
      </Modal>
    </Paper>
  );
}

const useHeader = ({
  onClick,
  userStatus,
  userRegisterStatus,
  actionType,
}: {
  actionType: any;
  onClick: (key: string, record: IAdmin) => void;
  userStatus: IReference[];
  userRegisterStatus: IReference[];
  loading: boolean;
}): ColumnType<IAdmin>[] => [
  {
    title: "Үйлдэл",
    align: "right",
    width: "120px",
    render: (record) => {
      return (
        <Flex>
          {record?.registerStatus !== "SUCCESS" ? (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("edit", record)}>
                <IconEdit />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Засварлах" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => {}}>
                <IconEdit stroke={0.4} />
              </Button>
            </Tooltip>
          )}
          <Tooltip label="Дэлгэрэнгүй" position="top">
            <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("details", record)}>
              <IconInfoSquareRounded />
            </Button>
          </Tooltip>
          {record?.registerStatus === "DRAFT" && (
            <Tooltip label="Урилга илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("send", record)}>
                <IconMailForward />
              </Button>
            </Tooltip>
          )}
          {record?.registerStatus === "INVITED" && (
            <Tooltip label="Урилга дахин илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("send", record)}>
                <IconMailOpened />
              </Button>
            </Tooltip>
          )}
          {record?.registerStatus === "PARTIAL" && (
            <Tooltip label="Урилга илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("send", record)}>
                <IconMailForward stroke={0.4} />
              </Button>
            </Tooltip>
          )}
          {record?.registerStatus === "SUCCESS" && (
            <Tooltip label="Урилга илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("send", record)}>
                <IconMailForward stroke={0.4} />
              </Button>
            </Tooltip>
          )}
          {record?.registerStatus === "INVITED" || record?.registerStatus === "DRAFT" ? (
            <Tooltip label="Устгах" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("remove", record)}>
                <IconTrash />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Устгах" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconTrash stroke={0.4} />
              </Button>
            </Tooltip>
          )}
          {record?.registerStatus === "PARTIAL" && record?.isPhoneVerified === false && record?.isEmailVerified === true ? (
            <Tooltip label="OTP илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("otp", record)}>
                <IconReload />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="OTP илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconReload stroke={0.4} />
              </Button>
            </Tooltip>
          )}
          {record?.hasPassword === false && record?.isPhoneVerified === true && record?.isEmailVerified === true ? (
            <Tooltip label="Нууц үг сэргээх мэйл илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("password", record)}>
                <IconSend size={20} style={{ transform: "rotate(45deg)" }} />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip label="Нууц үг сэргээх мэйл илгээх" position="top">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("", record)}>
                <IconSend size={20} style={{ transform: "rotate(45deg)" }} stroke={0.4} />
              </Button>
            </Tooltip>
          )}
          {/* {actionType[1]?.adminId !== null && record?.userStatus === "INACTIVE" && ( */}
          {record?.userStatus === "INACTIVE" && (
            <Tooltip label="Идэвхтэй болгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("active", record)}>
                <IconCircleCheck />
              </Button>
            </Tooltip>
          )}
          {actionType[1]?.adminId !== null && record?.userStatus === "ACTIVE" && (
            <Tooltip label="Идэвхгүй болгох" position="bottom">
              <Button variant="light" radius={100} w={35} h={35} p={0} onClick={() => onClick("in_active", record)}>
                <IconCircleMinus />
              </Button>
            </Tooltip>
          )}
        </Flex>
      );
    },
  },
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Хэрэглэгчийн нэр",
    sorter: true,
    dataIndex: "username",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.username || "-"}
        </Text>
      );
    },
  },
  {
    title: "Ургийн овог",
    sorter: true,
    dataIndex: "familyName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.familyName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Эцэг эхийн нэр",
    sorter: true,
    dataIndex: "lastName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.lastName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Өөрийн нэр",
    sorter: true,
    dataIndex: "firstName",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.firstName || "-"}
        </Text>
      );
    },
  },
  {
    title: "Регистр №",
    sorter: true,
    dataIndex: "registerNo",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.registerNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэгжийн нэр",
    sorter: true,
    dataIndex: "departmentUnit",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.departmentUnit?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "Албан тушаал",
    sorter: true,
    dataIndex: "employeeUnit",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.employeeUnit?.name || "-"}
        </Text>
      );
    },
  },
  {
    title: "И-мэйл хаяг",
    sorter: true,
    dataIndex: "email",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.email || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас 1",
    sorter: true,
    dataIndex: "phone",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.phone || "-"}
        </Text>
      );
    },
  },
  {
    title: "Утас 2",
    sorter: true,
    dataIndex: "phone2",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {record?.phone2 || "-"}
        </Text>
      );
    },
  },
  {
    title: "Бүртгэсэн огноо",
    sorter: true,
    dataIndex: "createdAt",
    render: (record) => {
      return (
        <Text size="sm" fw={500} c="#44566C">
          {dateSecFormat(record?.createdAt)}
        </Text>
      );
    },
  },
  {
    title: "Хэрэглэгч статус",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => (
      <Tag
        color={userStatus.find((item: IReference) => item.code === record.userStatus)?.color || "gray"}
        name={userStatus.find((item: IReference) => item.code === record.userStatus)?.name || "-"}
      />
    ),
  },
  {
    title: "Статус (Бүртгэл баталгаажилт)",
    sorter: true,
    dataIndex: "itemStatus",
    render: (record) => (
      <Tag
        color={userRegisterStatus?.find((item: IReference) => item.code === record.registerStatus)?.color || "gray"}
        name={userRegisterStatus?.find((item: IReference) => item.code === record.registerStatus)?.name || "-"}
      />
    ),
  },
  {
    title: "Утас (Бүртгэл баталгаажилт)",
    sorter: true,
    dataIndex: "isPhoneVerified",
    render: (record) => (
      <Flex gap={8} align="center">
        <Checkbox onChange={() => {}} checked={record.isPhoneVerified} /> {record.isPhoneVerified ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
  {
    title: "И-мэйл (Бүртгэл баталгаажилт)",
    sorter: true,
    dataIndex: "isEmailVerified",
    render: (record) => (
      <Flex gap={8} align="center">
        <Checkbox onChange={() => {}} checked={record.isEmailVerified} /> {record.isEmailVerified ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
  {
    title: "Нууц үг (Бүртгэл баталгаажилт)",
    sorter: true,
    dataIndex: "hasPassword",
    render: (record) => (
      <Flex gap={8} align="center">
        <Checkbox onChange={() => {}} checked={record.hasPassword} /> {record.hasPassword ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
  {
    title: "Төр (Бүртгэл баталгаажилт)",
    sorter: true,
    dataIndex: "isDanVerified",
    render: (record) => (
      <Flex gap={8} align="center">
        <Checkbox onChange={() => {}} checked={record.isDanVerified} /> {record.isDanVerified ? "Тийм" : "Үгүй"}
      </Flex>
    ),
  },
];
